import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DashboardNavbar from "../components/Navbar/DashboardNavbar";
import { VaultType } from "../data/data";
import {
  fetchVaultsData,
  getEligibleZap,
  getNetworkPools,
} from "../helpers/base";
import { bscPools, polygonPools } from "../helpers/configure";
import { initializePriceCache } from "../helpers/fetchPrice";
import { getTokens } from "../helpers/getTokens";
import { connectWallet, selectedChainId } from "../helpers/util";
import {
  FETCH_TOKENS_SUCCESS,
  FETCH_VAULT_SUCCESS,
} from "../redux/apy/actionTypes";
import {
  getApysSelector,
  getTokensSelector,
  getVaultsSelector,
} from "../redux/apy/selector";
import { FETCH_CONNECTION_SUCCESS } from "../redux/wallet/actionTypes";
import {
  getAddressSelector,
  getProviderSelector,
  getSignerSelector,
} from "../redux/wallet/selector";
import { fetchBalance } from "../helpers/fetchBalance";

type DashboardLayoutProps = {
  children: JSX.Element;
};

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  initializePriceCache(selectedChainId);
  const apys = useSelector(getApysSelector);
  const pools = useSelector(getVaultsSelector);
  const tokens = useSelector(getTokensSelector);
  const provider = useSelector(getProviderSelector);
  const signer = useSelector(getSignerSelector);
  const address = useSelector(getAddressSelector);
  useEffect(() => {
    function getListOfVaults() {
      if (apys && provider && signer) {
        let tokens: any = {};
        let arr: Array<VaultType | null> = [];
        getNetworkPools(selectedChainId).forEach((item, index) => {
          if (apys[item.id]) {
            let obj = { ...item, chain_icon: "binance" };
            const zap = getEligibleZap(item, selectedChainId);
            if (zap) {
              obj["zap"] = zap;
            }
            arr.push(obj);
          }
        });

        const data = fetchVaultsData(
          provider,
          signer,
          arr,
          selectedChainId,
          dispatch
        );
        tokens = { ...getTokens(arr, selectedChainId) };
        console.log("array:", Object.keys(tokens));
        dispatch({
          type: FETCH_VAULT_SUCCESS,
          payload: arr,
        });
        dispatch({
          type: FETCH_TOKENS_SUCCESS,
          payload: tokens,
        });
      }
    }
    getListOfVaults();
  }, [apys, provider, signer]);
  useEffect(() => {
    async function getProvider() {
      let pro = await connectWallet();
      dispatch({
        type: FETCH_CONNECTION_SUCCESS,
        payload: {
          signer: pro[1],
          provider: pro[0],
          address: pro[2],
        },
      });
    }
    getProvider();
  }, []);
  useEffect(() => {
    if (tokens) {
      fetchBalance(tokens, address, provider);
    }
  }, [tokens]);

  return (
    <div>
      <DashboardNavbar />
      {/* <DashboardSidebar /> */}
      {children}
    </div>
  );
};

export default DashboardLayout;
