import { FC } from 'react'
import { GetIconByName } from '../../helpers/getIconByName';

interface SendTokenImagesProps {
    assets:Array<string | null>;
    size?: number;
}

const SendTokenImages: FC<SendTokenImagesProps> = (
    {
        assets,
        size = 32
    }
) => {
    const handleFormattedImages = () =>{
        if(assets.length === 1){
            return <GetIconByName name={assets[0]} size={size} />;
        }else if(assets.length === 2){
            return (
                <div className='relative' style={{height:size, width:size}}>
                    <div className='absolute z-[11] top-[10%] left-0'>
                        <GetIconByName name={assets[0]} size={size/1.3} />
                    </div>
                    <div className='absolute z-[10] left-[30%]'>
                        <GetIconByName name={assets[1] ? assets[1] : 'ethereum'} size={size/1.3} />
                    </div>
                </div>
            )
        }
        else if(assets.length === 3){
            return (
                <div className='relative' style={{height:size, width:size}}>
                    <div className='absolute z-[11] top-[10%] left-0'>
                        <GetIconByName name={assets[0]} size={size/1.5} />
                    </div>
                    <div className='absolute z-[10] left-[30%]'>
                        <GetIconByName name={assets[1] ? assets[1] : 'ethereum'} size={size/1.5} />
                    </div>
                    <div className='absolute z-[9] top-[10%] left-[50%]'>
                        <GetIconByName name={assets[2]} size={size/1.5} />
                    </div>
                </div>
            )
        }
        else if(assets.length === 4){
            return (
                <div className='relative' style={{height:size, width:size}}>
                    <div className='absolute z-[11] top-[30%] left-0'>
                        <GetIconByName name={assets[0]} size={size/1.8} />
                    </div>
                    <div className='absolute z-[10] left-[50%]'>
                        <GetIconByName name={assets[1] ? assets[1] : 'ethereum'} size={size/1.8} />
                    </div>
                    <div className='absolute z-[9] top-[30%] left-[100%]'>
                        <GetIconByName name={assets[2]} size={size/1.8} />
                    </div>
                    <div className='absolute z-[8] top-[60%] left-[50%]'>
                        <GetIconByName name={assets[3]} size={size/1.8} />
                    </div>
                </div>
            )
        }
        else if(assets.length === 5){
            return (
                <div className='relative' style={{height:size, width:size}}>
                    <div className='absolute z-[11] top-[30%] left-0'>
                        <GetIconByName name={assets[0]} size={size/2} />
                    </div>
                    <div className='absolute z-[10] left-[50%]'>
                        <GetIconByName name={assets[1] ? assets[1] : 'ethereum'} size={size/2} />
                    </div>
                    <div className='absolute z-[9] top-[30%] left-[100%]'>
                        <GetIconByName name={assets[2]} size={size/2} />
                    </div>
                    <div className='absolute z-[8] top-[60%] left-[50%]'>
                        <GetIconByName name={assets[3]} size={size/2} />
                    </div>
                    <div className='absolute z-[12] top-[30%] left-[50%]'>
                        <GetIconByName name={assets[4]} size={size/2} />
                    </div>
                </div>
            )
        }
    }
  return (
    <div>
        {handleFormattedImages()}
    </div>
  )
}

export default SendTokenImages