import BigNumber from "bignumber.js";
import {
  ContractCallContext,
  ContractCallResults,
  Multicall,
} from "ethereum-multicall";
import { ethers } from "ethers";
import { getNetworkMulticall } from "./base";
import { erc20ABI, launchPoolABI, multicallABI } from "./configure";
import { getNetworkLaunchpools } from "./network";
import { selectedChainId } from "./util";

export const fetchBalance = async (
  tokens: any,
  address: any,
  provider: any
) => {
  const balanceCalls = [];
  const allowanceCalls = [];
  const launchPoolBalanceCalls = [];
  const multicall = new Multicall({
    ethersProvider: provider,
    tryAggregate: true,
  });

  // Object.entries(tokens).forEach(([symbol, token]: any) => {
  //   if (!token.tokenAddress) {
  //     const multicallContract = new ethers.Contract(
  //       getNetworkMulticall(selectedChainId),
  //       multicallABI
  //     );
  //     balanceCalls.push({
  //       balance: multicallContract.getEthBalance(address),
  //       symbol: symbol,
  //     });
  //   } else {
  //     const tokenContract = new ethers.Contract(token.tokenAddress, erc20ABI);
  //     balanceCalls.push({
  //       balance: tokenContract.balanceOf(address),
  //       symbol: symbol,
  //     });
  //     Object.entries(token.allowance).forEach(([spender]) => {
  //       allowanceCalls.push({
  //         allowance: tokenContract.allowance(address, spender),
  //         spender: spender,
  //         symbol: symbol,
  //       });
  //     });
  //   }
  // });
  let launchpools = getNetworkLaunchpools(selectedChainId);
  // Object.entries(launchpools).forEach(([lpid, launchpool]) => {
  //   if (launchpool.earnContractAddress) {
  //     const multicallContract = new ethers.Contract(
  //       launchpool.earnContractAddress,
  //       launchPoolABI
  //     );
  //     launchPoolBalanceCalls.push({
  //       balance: multicallContract.balanceOf(address),
  //       symbol: launchpool.token,
  //     });
  //   }
  // });

  let contractCallContextBalance: ContractCallContext[] = [];
  let contractCallContextAllowance: ContractCallContext[] = [];
  let contractCallContextLaunchPools: ContractCallContext[] = [];
  console.log("Fakiraaaa", Object.keys(tokens))
  Object.entries(tokens).forEach(([symbol, token]: any) => {
    if (!token.tokenAddress) {
      contractCallContextBalance.push({
        reference: symbol,
        contractAddress: getNetworkMulticall(selectedChainId),
        abi: multicallABI,
        calls: [
          {
            reference: "getEthBalance",
            methodName: "getEthBalance",
            methodParameters: [address],
          },
        ],
      });
    } else {
      contractCallContextBalance.push({
        reference: symbol,
        contractAddress: token.tokenAddress,
        abi: erc20ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [address],
          },
        ],
      });
      Object.entries(token.allowance).forEach(([spender]) => {
        contractCallContextAllowance.push({
          reference: symbol,
          contractAddress: token.tokenAddress,
          abi: erc20ABI,
          calls: [
            {
              reference: "allowance",
              methodName: "allowance",
              methodParameters: [address, spender],
            },
          ],
        });
      });
    }
  });

  Object.entries(launchpools).forEach(([lpid, launchpool]) => {
    if (launchpool.earnContractAddress) {
      contractCallContextLaunchPools.push({
        reference: launchpool.token,
        contractAddress: launchpool.earnContractAddress,
        abi: launchPoolABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [address],
          },
        ],
      });
    }
  });

  const newTokens = {};

  const balances: ContractCallResults = await multicall.call(
    contractCallContextBalance
  );
  console.log("Updated balance:", balances);
  Object.keys(balances.results).forEach(balance => {
    newTokens[balance] = {
      ...tokens[balance],
      tokenBalance: balances.results[balance].callsReturnContext[0].returnValues[0],
      launchpoolTokenBalance: '0',
    };
  });

  const allowances: ContractCallResults = await multicall.call(
    contractCallContextAllowance
  );
  console.log("Updated allowance:", allowances);
  Object.keys(allowances.results).forEach(allowance => {
    newTokens[allowance] = {
      ...newTokens[allowance],
      allowance: {
        ...newTokens[allowance].allowance,
        [allowances.results[allowance].originalContractCallContext.calls[0].methodParameters[1]]: allowances.results[allowance].callsReturnContext[0].returnValues[0],
      },
    };
  });

  const launchPools: ContractCallResults = await multicall.call(
    contractCallContextLaunchPools
  );
  console.log("Updated Launch Pools:", launchPools);

  Object.keys(launchPools.results).forEach(lp => {
    const previousBalance = newTokens[lp]?.launchpoolTokenBalance || 0;
      newTokens[lp] = {
        ...newTokens[lp],
        launchpoolTokenBalance: BigNumber.sum(
          launchPools.results[lp].callsReturnContext[0].returnValues[0],
          previousBalance
        ).toString(),
      };
    });
  console.log("Updated Tokens:", newTokens);
};
