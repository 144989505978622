import React, { FC } from "react";

interface CustomRadioProps {
  colorClass?: string;
  selected: boolean;
  onClick: () => void;
}

const CustomRadio: FC<CustomRadioProps> = ({ colorClass, selected, onClick }) => {
  return (
    <div
      className={`${
        colorClass ? colorClass : "border-white"
      } border-[1px] rounded-full h-[22px] w-[22px] flex justify-center items-center cursor-pointer`}
      onClick={() => onClick()}
    >
      {selected ? (
        <div
          className={`${
            colorClass ? colorClass : "bg-white"
          } h-[16px] w-[16px] rounded-full`}
        ></div>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default CustomRadio;
