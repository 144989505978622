import React, { FC } from "react";

interface TransferRowProps {
  leftChild?: JSX.Element;
  rightChild?: JSX.Element;
  isLast?: boolean;
}

const TransferRow: FC<TransferRowProps> = ({
  leftChild,
  rightChild,
  isLast = false,
}) => {
  return (
    <div className="w-full flex justify-between relative min-h-[50px] h-auto">
      <div className="w-[48%] relative px-2">
        <div className="w-full flex justify-end">{leftChild}</div>
      </div>
      <div className="w-[4%] relative flex flex-col items-center">
        <span className="min-w-[16px] min-h-[16px] bg-white rounded-full border-[3px] border-color-primary"></span>
        {!isLast && <span className="h-full w-[2px] bg-color-primary"></span>}
      </div>
      <div className="w-[48%] relative px-2">{rightChild}</div>
    </div>
  );
};

export default TransferRow;
