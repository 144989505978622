import React from "react";
import { ReactComponent as EthereumIcon } from "../assets/svg/icons/Ethereum.svg";
import { ReactComponent as AvalancheIcon } from "../assets/svg/icons/Avalanche.svg";
import { ReactComponent as BinanceIcon } from "../assets/svg/icons/Binance.svg";
import { ReactComponent as AuroraIcon } from "../assets/svg/icons/Aurora.svg";
import { ReactComponent as FantomIcon } from "../assets/svg/icons/Fantom.svg";
import { ReactComponent as NearIcon } from "../assets/svg/icons/Near.svg";
import { ReactComponent as PolygonIcon } from "../assets/svg/icons/Polygon.svg";
import { ReactComponent as TerraIcon } from "../assets/svg/icons/Terra.svg";
import { ReactComponent as XDAIIcon } from "../assets/svg/icons/XDAI.svg";
import { ReactComponent as REAIcon } from "../assets/svg/icons/rea-icon.svg";
import { ReactComponent as BIFIIcon } from "../assets/svg/icons/bifi.svg";
import { ReactComponent as USDTIcon } from "../assets/svg/icons/usdt.svg";
import { ReactComponent as BUSDIcon } from "../assets/svg/icons/busd.svg";

interface IconByNameProps {
  name: string;
  size?: number;
}

export const GetIconByName = ({ name, size }: IconByNameProps) => {
  const handleIconByName = () => {
    let fname = name ? name.toLowerCase() : "";
    switch (fname) {
      case "ethereum":
        return (
          <EthereumIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "polygon":
        return (
          <PolygonIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "matic":
        return (
          <PolygonIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "pmatic":
        return (
          <PolygonIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "wmatic":
        return (
          <PolygonIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "avalanche":
        return (
          <AvalancheIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "aurora":
        return (
          <AuroraIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "xdai":
        return <XDAIIcon height={size ? size : 32} width={size ? size : 32} />;
      case "binance":
        return (
          <BinanceIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "bnb":
        return (
          <BinanceIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "near":
        return <NearIcon height={size ? size : 32} width={size ? size : 32} />;
      case "fantom":
        return (
          <FantomIcon height={size ? size : 32} width={size ? size : 32} />
        );
      case "terra":
        return <TerraIcon height={size ? size : 32} width={size ? size : 32} />;
      case "rea":
        return <REAIcon height={size ? size : 32} width={size ? size : 32} />;
      case "bifi":
        return <BIFIIcon height={size ? size : 32} width={size ? size : 32} />;
      case "usdt":
        return <USDTIcon height={size ? size : 32} width={size ? size : 32} />;
      case "busd":
        return <BUSDIcon height={size ? size : 32} width={size ? size : 32} />;
      default:
        return (
          <EthereumIcon height={size ? size : 32} width={size ? size : 32} />
        );
    }
  };
  return <div>{handleIconByName()}</div>;
};
