import BigNumber from "bignumber.js";

const units = ["", "k", "M", "B", "T", "Q", "Q", "S", "S"];

export const formatApy = (apy, dp = 2) => {
  if (!apy) return `???`;

  apy *= 100;

  const order = apy < 1 ? 0 : Math.floor(Math.log10(apy) / 3);
  if (order >= units.length - 1) return `🔥`;

  const num = apy / 1000 ** order;
  return `${num.toFixed(dp)}${units[order]}%`;
};

export const formatTvl = (tvl, oraclePrice, useOrder = true) => {
  if (oraclePrice) {
    tvl = new BigNumber(tvl).times(oraclePrice).toFixed(2);
  }
  let order = Math.floor(Math.log10(tvl) / 3);
  if (order < 0 || useOrder === false) {
    order = 0;
  }

  const num = tvl / 1000 ** order;

  return "$" + num.toFixed(2) + units[order];
};
