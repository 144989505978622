import { FC } from 'react'
import CustomRadio from '../core/CustomRadio';
import SendTokenImages from '../core/SendTokenImages';

interface ZapTokenListProps {
    data: any;
    selected: boolean;
    handleSelect: (value: any) => void;
    displayKey?: string;
}

const ZapTokenList: FC<ZapTokenListProps>= ({
    data,
    selected,
    handleSelect,
    displayKey = "name"
}) => {
  return (
    <div className="flex justify-start items-center mb-4">
        <div className='mr-3'>
            <CustomRadio
            selected={selected}
            onClick={() => handleSelect(data)}
          />
        </div>
        <div className='flex items-center'>
            <div className='min-h-[24px] min-w-[24px] mr-4'>
                <SendTokenImages size={22} assets={data.token} />
            </div>
            {data[displayKey]}
        </div>
    </div>
  )
}

export default ZapTokenList