import React, { FC } from "react";
import { twMerge } from "tailwind-merge";
interface CustomInputProps {
  onChange: (event: any) => void;
  placeholder?: string;
  type?: string;
  inputClasses?: string;
  icon?: JSX.Element;
  iconRight?: JSX.Element;
  isDebounced?: boolean;
  value: string | number;
  disabled?: boolean;
}

const CustomInput: FC<CustomInputProps> = ({
  onChange,
  placeholder = "Enter a value",
  type = "text",
  inputClasses = "",
  icon,
  isDebounced = false,
  iconRight,
  value,
  disabled = false
}) => {
  return (
    <div className="flex justify-between items-center relative">
      {icon ? (
        <span className="absolute top-[50%] left-4 -translate-y-2/4">
          {icon}
        </span>
      ) : (
        <></>
      )}
      <input
        type={type}
        className={twMerge(
          `border-white border-opacity-50 outline-none border-[1px] px-4 py-2 text-white bg-transparent rounded ${
            icon ? "pl-14" : "pl-4"
          }
          ${iconRight ? "pr-14" : "pr-4"}
          `,
          inputClasses
        )}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        disabled={disabled}
      />
      {iconRight ? (
        <span className="absolute top-[50%] right-4 -translate-y-2/4">
          {iconRight}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomInput;
