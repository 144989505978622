import { BigNumberish } from 'ethers';
import { TransactionRequest } from 'etherspot';
import {
  erc20ABI,
} from '../configure/abi';
import { BaseContract } from './Base';

export class ERC20Contract extends BaseContract {
  constructor(address: string) {
    super('ERC20', erc20ABI, address);
  }

  callAllowance?(_owner: string, _spender: string): Promise<BigNumberish>;
  callBalanceOf?(_owner: string): Promise<BigNumberish>;
  callDecimals?(): Promise<BigNumberish>;
  callName?(): Promise<string>;
  callSymbol?(): Promise<string>;
  callTotalSupply?(): Promise<BigNumberish>;

  encodeApprove?(_spender: string, _value: BigNumberish): TransactionRequest;
  encodeShowMeTheMoney?(_to: string, _value: BigNumberish): TransactionRequest;
  encodeTransfer?(_to: string, _value: BigNumberish): TransactionRequest;
  encodeTransferFrom?(_from: string, _to: string, _value: BigNumberish): TransactionRequest;
}
