import { createSelector } from "reselect";

import { AppState } from "../rootReducer";


const getAddress = (state: AppState) => state.wallet.address;
const getSigner = (state: AppState) => state.wallet.signer;
const getProvider = (state: AppState) => state.wallet.provider;
const getConnected = (state: AppState) => state.wallet.connected;



export const getAddressSelector = createSelector(getAddress, (address) => address);
export const getConnectedSelector = createSelector(getConnected, (connected) => connected);
export const getSignerSelector = createSelector(getSigner, (signer) => signer);
export const getProviderSelector = createSelector(getProvider, (provider) => provider);
