import { BigNumberish } from "ethers";
import { TransactionRequest } from "etherspot";
import { beefyUniV2ZapABI } from "../configure/abi";
import { BaseContract } from "./Base";

export class BeefyUniV2ZapContract extends BaseContract {
  constructor(address: string) {
    super("BeefyUniV2Zap", beefyUniV2ZapABI, address);
  }

  callCheckWETH?(): Promise<boolean>;
  callEstimateSwap?(
    beefyVault: string,
    tokenIn: string,
    fullInvestmentIn: BigNumberish
  ): Promise<[BigNumberish, BigNumberish, string]>;
  callMinimumAmount?(): Promise<BigNumberish>;
  callRouter?(): Promise<string>;
  callWETH?(): Promise<string>;

  encodeBeefIn?(
    beefyVault: string,
    tokenAmountOutMin: BigNumberish,
    tokenIn: string,
    tokenInAmount: BigNumberish
  ): TransactionRequest;
  encodeBeefInETH?(
    beefyVault: string,
    tokenAmountOutMin: BigNumberish
  ): TransactionRequest;
  encodeBeefOut?(
    beefyVault: string,
    withdrawAmount: BigNumberish
  ): TransactionRequest;
  encodeBeefOutAndSwap?(
    beefyVault: string,
    withdrawAmount: BigNumberish,
    desiredToken: string,
    desiredTokenOutMin: BigNumberish
  ): TransactionRequest;
}
