import { AnyCnameRecord } from "dns";
import {
    FETCH_APY_REQUEST,
    FETCH_APY_FAILURE,
    FETCH_APY_SUCCESS,
  } from "./actionTypes";
  import {
    FetchApyRequest,
    FetchApySuccess,
    FetchApySuccessPayload,
    FetchApyFailure,
    FetchApyFailurePayload,
  } from "./types";
  
  export const fetchApyRequest = (): FetchApyRequest => ({
    type: FETCH_APY_REQUEST,
  });
  
  export const fetchApySuccess = (
    payload: FetchApySuccessPayload
  ): FetchApySuccess => ({
    type: FETCH_APY_SUCCESS,
    payload,
  });
  
  export const fetchApyFailure = (
    payload: FetchApyFailurePayload
  ): FetchApyFailure => ({
    type: FETCH_APY_FAILURE,
    payload,
  });

  export const fetchVaultSuccess = (
    payload: any
  ): FetchApySuccess => ({
    type: FETCH_APY_SUCCESS,
    payload,
  });