import React, { FC } from "react";
import { savityData, SavityType } from "../../data/data";
import BarGenerator from "../core/BarGenerator";
import GlassCard from "../core/GlassCard";
import Point from "./Point";

const SavityScore: FC = () => {
  return (
    <GlassCard cardStyleClasses="min-h-[70vh] h-auto w-full overflow-hidden mb-8">
      <div>
        <div className="h-[78px] flex justify-center w-full relative  backdrop-blur-md bg-opacity-50 border border-gray-700 text-white mb-4 font-medium">
          <div className="px-8 py-2 w-full backdrop-filter bg-gray-700 bg-clip-padding cursor-pointer text-font-size-h4 h-full flex flex-col justify-center items-start">
            <div className="flex justify-end items-start">
              <span className="mr-2 text-font-size-h4 font-semibold">8.0</span>
              <span>
                <BarGenerator barNumber={8} barHeight={25} barWidth={7} />
              </span>
            </div>
            <div className="uppercase text-base text-neutral-400">
              SAVITY SCORE
            </div>
          </div>
        </div>
        <div className="px-8 mb-6">
          {savityData.map((savity: SavityType, index: number) => {
            return (
              <Point
                key={index}
                coin={savity.coin}
                point={savity.point}
                status={savity.status}
              />
            );
          })}
        </div>
        <div className="px-8 text-font-size-h6 text-neutral-500 py-4">
          <div>
            The higher the REA Safely Score, the safer your investment.
            <br />
            <br />
            Your fund stay scure on REA. The team puts a lot effort into
            checking vaults added. Each vault also has a panic facility to pause
            activity to protect user funds at short notice if the need arises.
            or even susicious activity identified
          </div>
        </div>
      </div>
    </GlassCard>
  );
};

export default SavityScore;
