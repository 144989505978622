import {
  computePairAddress,
  getNetworkBurnTokens,
  getNetworkCoin,
  getNetworkTokens,
  getNetworkZaps,
} from "./base";

export const getTokens = (pools: any, id: number) => {
  let tokens = {};
  let zapMap = {};
  pools.forEach(
    (
      {
        token,
        tokenDecimals,
        tokenAddress,
        earnedToken,
        earnContractAddress,
        earnedTokenAddress,
        withdrawalFee,
        depositFee,
      },
      i
    ) => {
      if (!withdrawalFee) pools[i].withdrawalFee = "0.1%";
      if (!depositFee) pools[i].depositFee = "0%";

      tokens[token] = {
        symbol: token,
        decimals: tokenDecimals,
        tokenAddress: tokenAddress,
        tokenBalance: 0,
        allowance: {
          ...tokens[token]?.allowance,
          [earnContractAddress]: tokenAddress ? 0 : Infinity,
        },
      };
      tokens[earnedToken] = {
        symbol: earnedToken,
        decimals: 18,
        tokenAddress: earnedTokenAddress,
        tokenBalance: 0,
        allowance: {
          [earnContractAddress]: 0,
        },
      };

      const zap = getEligibleZap(pools[i], id);
      if (zap) {
        tokens[token].allowance[zap.zapAddress] = tokenAddress ? 0 : Infinity;
        tokens[earnedToken].allowance[zap.zapAddress] = 0;
        pools[i]["zap"] = zap;
        zapMap[pools[i].id] = zap;
      }
    }
  );
  return tokens;
};

export const getEligibleZap = (pool: any, id: number) => {
  const availableZaps = getNetworkZaps(id);
  const availableTokens = getNetworkTokens(id);
  const burnTokens = getNetworkBurnTokens(id);
  const nativeCoin = getNetworkCoin(id);
  if (pool.assets.length !== 2) return undefined;

  const eligibleNativeCoin = [];
  const tokenSymbols = pool.assets.map((symbol) => {
    if (nativeCoin.symbol === symbol) {
      const wrappedToken = availableTokens[nativeCoin.wrappedSymbol];
      nativeCoin.address = wrappedToken.address;
      eligibleNativeCoin.push(nativeCoin);
      return nativeCoin.wrappedSymbol;
    }
    return symbol;
  });

  let tokenA, tokenB, tokenASymbol, tokenBSymbol;
  let missingTokenSymbols = {};
  const zap = availableZaps.find((zap) => {
    tokenASymbol = tokenSymbols[0];
    tokenBSymbol = tokenSymbols[1];
    tokenA = availableTokens[tokenASymbol];
    tokenB = availableTokens[tokenBSymbol];
    if (tokenA && tokenB) {
      return (
        pool.tokenAddress ===
        computePairAddress(
          zap.ammFactory,
          zap.ammPairInitHash,
          tokenA.address,
          tokenB.address
        )
      );
    } else {
      if (!tokenA) {
        missingTokenSymbols[tokenASymbol] = "";
      }
      if (!tokenB) {
        missingTokenSymbols[tokenBSymbol] = "";
      }
    }
    return false;
  });

  for (const symbol in missingTokenSymbols) {
    console.error("Beefy: token missing in the tokenlist:", symbol);
  }

  const pairHasBurnToken =
    tokenASymbol in burnTokens || tokenBSymbol in burnTokens;
  if (!zap || pairHasBurnToken) return undefined;

  tokenA.allowance = 0;
  tokenB.allowance = 0;

  return {
    ...zap,
    tokens: [tokenA, tokenB, ...eligibleNativeCoin],
  };
};
