import axios from "axios";
import { getNetworkPools } from "./base";
import { apiUrl, getApiCacheBuster } from "./getApiInfo";
import { getNetworkLaunchpools } from "./network";
import { selectedChainId } from "./util";

const CACHE_TIMEOUT_MS = 1 * 60 * 1000; // 1 minute(s)


const priceCache = {
    cache: new Map(),
    lastUpdated: undefined,
  };
  
  const oracleEndpoints = {
    tokens: () => fetchTokens(),
    lps: () => fetchLPs(),
  };
  let pricesLoadedPromise;
  export function whenPricesLoaded() {
    return pricesLoadedPromise;
  }
  function getCachedPrice(id) {
    return priceCache.cache.get(id);
  }
  
  const fetchTokens = async () => {
    const cacheBuster = getApiCacheBuster();
  
    try {
      const response = await axios.get(`${apiUrl}/prices?_=${cacheBuster}`);
      return response.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  const fetchLPs = async () => {
    const cacheBuster = getApiCacheBuster();
  
    try {
      const response = await axios.get(`${apiUrl}/lps?_=${cacheBuster}`);
      return response.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  export function initializePriceCache(zap:number) {
    const currentTimestamp = new Date();
    const launchpools = getNetworkLaunchpools(zap)
    priceCache.lastUpdated = currentTimestamp;
    const pools = getNetworkPools(zap);
    const oracleToIds = new Map();
    pools.forEach(pool => {
      if (!oracleToIds.has(pool.oracle)) {
        oracleToIds.set(pool.oracle, []);
      }
      oracleToIds.get(pool.oracle).push(pool.oracleId);
    });
  
    // BIFI should always be fetched
    if (!oracleToIds.has('tokens')) {
      oracleToIds.set('tokens', []);
    }
    oracleToIds.get('tokens').push('BIFI');
  
    Object.values(launchpools).forEach(pool => {
      if (!oracleToIds.has(pool.earnedOracle)) {
        oracleToIds.set(pool.earnedOracle, []);
      }
      oracleToIds.get(pool.earnedOracle).push(pool.earnedOracleId);
    });
  
    const promises = [...oracleToIds.keys()].map(key => oracleEndpoints[key](oracleToIds.get(key)));
    pricesLoadedPromise = Promise.all(promises).then(results => {
      const allPrices = results.reduce(
        (accPrices, curPrices) => ({ ...accPrices, ...curPrices }),
        {}
      );
      [...oracleToIds.values()].flat().forEach(id => priceCache.cache.set(id, allPrices[id]));
    });
  }

function maybeUpdateCache(zap: number) {
    const currentTimestamp = new Date();
    if (
      priceCache.lastUpdated &&
      currentTimestamp.getTime() > priceCache.lastUpdated.getTime() + CACHE_TIMEOUT_MS
    ) {
      initializePriceCache(zap);
      // console.trace('price cache updated')
    }
  }
  

export const fetchPrice = ({ id }) => {
    if (id === undefined) {
      console.error('Undefined pair');
      return 0;
    }
  
    maybeUpdateCache(selectedChainId);
  
    return getCachedPrice(id) || 0;
  };