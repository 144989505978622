import { BigNumberish } from "ethers";
import { TransactionRequest } from "etherspot";
import { vaultABI } from "../configure/abi";
import { BaseContract } from "./Base";

export class VaultContract extends BaseContract {
  constructor(address: string) {
    super("Vault", vaultABI, address);
  }

  callAllowance?(owner: string, spender: string): Promise<BigNumberish>;
  callAvailable?(): Promise<BigNumberish>;
  callBalance?(): Promise<BigNumberish>;
  callBalanceOf?(account: string): Promise<BigNumberish>;
  callController?(): Promise<string>;
  callDecimals?(): Promise<BigNumberish>;
  callEarnLowerlimit?(): Promise<BigNumberish>;
  callGetPricePerFullShare?(): Promise<BigNumberish>;
  callGovernance?(): Promise<string>;
  callMax?(): Promise<BigNumberish>;
  callMin?(): Promise<BigNumberish>;
  callName?(): Promise<string>;
  callOwner?(): Promise<string>;
  callStrategy?(): Promise<string>;
  callSymbol?(): Promise<string>;
  callToken?(): Promise<string>;
  callTotalSupply?(): Promise<BigNumberish>;

  encodeApprove?(spender: string, amount: BigNumberish): TransactionRequest;
  encodeDecreaseAllowance?(
    spender: string,
    subtractedValue: BigNumberish
  ): TransactionRequest;
  encodeDeposit?(_amount: BigNumberish): TransactionRequest;
  encodeDepositAll?(): TransactionRequest;
  encodeDepositETH?(): TransactionRequest;
  encodeEarn?(): TransactionRequest;
  encodeIncreaseAllowance?(
    spender: string,
    addedValue: BigNumberish
  ): TransactionRequest;
  encodeSetController?(_controller: string): TransactionRequest;
  encodeSetEarnLowerlimit?(_earnLowerlimit: BigNumberish): TransactionRequest;
  encodeSetGovernance?(_governance: string): TransactionRequest;
  encodeSetMin?(_min: BigNumberish): TransactionRequest;
  encodeTransfer?(recipient: string, amount: BigNumberish): TransactionRequest;
  encodeTransferFrom?(
    sender: string,
    recipient: string,
    amount: BigNumberish
  ): TransactionRequest;
  encodeWithdraw?(_shares: BigNumberish): TransactionRequest;
  encodeWithdrawAll?(): TransactionRequest;
  encodeWithdrawAllETH?(): TransactionRequest;
  encodeWithdrawETH?(_shares: BigNumberish): TransactionRequest;
}
