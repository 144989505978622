import React from 'react'
import { Link } from "react-router-dom";

interface CustomLinkProps  {
    href: string;
    calsses?: string;
    text: string;
}

const CustomLink = ({href, calsses, text} : CustomLinkProps) => {
  return (
    <Link to = {href} className={calsses}>
        {text}
    </Link>
  )
}

export default CustomLink