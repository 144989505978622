import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getApiCacheBuster } from "../../helpers/getApiInfo";

import { fetchApyFailure, fetchApySuccess } from "./actions";
import { FETCH_APY_REQUEST } from "./actionTypes";
import { ITodo } from "./types";

const getApys = () =>
  axios.get<any>("https://api.beefy.finance/apy/breakdown?_=" + getApiCacheBuster());

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchApySaga() {
  try {
    const response = yield call(getApys);
    yield put(
      fetchApySuccess({
        apys: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchApyFailure({
        error: e.message,
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_Apy_REQUEST` action.
  Allows concurrent increments.
*/
function* apySaga() {
  yield all([takeLatest(FETCH_APY_REQUEST, fetchApySaga)]);
}

export default apySaga;