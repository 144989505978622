import "./App.css";
import { useEffect } from "react";
import DashboardLayout from "./Layout/DashboardLayout";
import AppRoutes from "./routes/AppRoutes";
import { useDispatch, useSelector } from "react-redux";
import { fetchApyRequest } from "./redux/apy/actions";
import { getApysSelector } from "./redux/apy/selector";
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchApyRequest());
  }, []);
  return (
    <div>
      <DashboardLayout>
        <AppRoutes />
      </DashboardLayout>
    </div>
  );
}

export default App;
