import React, { FC } from "react";
import ReactModal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(1,1,1, 0.7)",
  },
};

interface CustomModalProps {
  modalIsOpen: boolean;
  handleClose: (value?: any) => void;
  children: JSX.Element;
}

const CustomModal: FC<CustomModalProps> = ({
  modalIsOpen,
  handleClose,
  children,
}) => {
  function afterOpenModal() {
    let BodyElement = document.querySelector("body") as HTMLElement;
    BodyElement.style.overflow = "hidden";
  }
  function closeModal() {
    handleClose();
  }
  return (
    <div>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {children}
      </ReactModal>
    </div>
  );
};

export default CustomModal;
