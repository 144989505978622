import React, { FC, useEffect } from "react";
import GlassCard from "../core/GlassCard";
import CategoryItem from "./CategoryItem";
interface CategoriesProps {
  onChange: (value: any) => void;
  selectedCategory: number;
  options: CategoryType[];
  title: string;
}
export type CategoryType = {
  name: string;
  value: number;
};

const Categories: FC<CategoriesProps> = ({
  onChange,
  selectedCategory,
  options,
  title,
}) => {
  return (
    <div className="flex flex-col items-start justify-between w-[35%] relative">
      <div className="mb-4 uppercase">{title}</div>
      <div className="flex items-start justify-between w-full relative">
        <GlassCard cardStyleClasses="h-[40px] overflow-hidden rounded-[2px]">
          <div className="w-full flex h-full relative">
            {options.map((options, index) => {
              return (
                <CategoryItem
                  item={options}
                  onChange={onChange}
                  isSelected={selectedCategory === options.value}
                />
              );
            })}
          </div>
        </GlassCard>
      </div>
    </div>
  );
};

export default Categories;
