export type VaultType = {
    id: number | string,
    name?: string,
    from?: string,
    to?: string,
    logo?: string,
    wallet?: number,
    deposit?: number,
    apy?: number,
    daily?: number,
    tvl?: number,
    savity_score?: number,
    risk?: string,
    chain?: string,
    platform?: string,
    chain_icon?: string,
    token?: string,
    tokenDescription?: string,
    tokenAddress?: string,
    tokenDecimals?: number,
    tokenDescriptionUrl?: string,
    earnedToken?: string,
    earnedTokenAddress?: string,
    earnContractAddress?: string,
    pricePerFullShare?: number,
    oracle?: string,
    oracleId?: string,
    oraclePrice?: number,
    depositsPaused?: boolean,
    status?: string,
    assets?: Array<string>,
    withdrawalFee?: string,
    risks?: Array<string>,
    stratType?: string,
    buyTokenUrl?: string,
    createdAt?:number,
    addLiquidityUrl?: string,
    zap?: any,
    zapEstimate?: any,
    zapWithdrawEstimate?: any,
}
export type SavityType = {
    point: string;
    coin: string;
    status: 'high' | 'low';
}
export const vaultData : VaultType[] = [
    {
        id: 'bifi-maxi',
        logo: 'single-assets/BIFI.png',
        name: 'BIFI Maxi',
        token: 'BIFI',
        tokenDescription: 'Beefy',
        tokenAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
        tokenDecimals: 18,
        earnedToken: 'mooBIFIV2',
        earnedTokenAddress: '0x7828ff4ABA7aAb932D8407C78324B069D24284c9',
        earnContractAddress: '0x7828ff4ABA7aAb932D8407C78324B069D24284c9',
        pricePerFullShare: 1,
        tvl: 0,
        oracle: 'tokens',
        oracleId: 'BIFI',
        oraclePrice: 0,
        depositsPaused: false,
        status: 'active',
        platform: 'Beefy',
        assets: ['BIFI'],
        risks: [
          'COMPLEXITY_LOW',
          'BATTLE_TESTED',
          'IL_NONE',
          'MCAP_MEDIUM',
          'AUDIT',
          'CONTRACTS_VERIFIED',
        ],
        stratType: 'SingleStake',
        withdrawalFee: '0%',
        buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BIFI',
        createdAt: 1648477070,
      },
      {
        id: 'baby-bnb-usdt',
        name: 'BNB-USDT LP',
        token: 'BNB-USDT LP',
        tokenDescription: 'BabySwap',
        tokenAddress: '0x04580ce6dEE076354e96fED53cb839DE9eFb5f3f',
        tokenDecimals: 18,
        tokenDescriptionUrl: '#',
        earnedToken: 'mooBabyBNB-USDT',
        earnedTokenAddress: '0xFC744521a921fEc005Fe5e91cca09878086E8526',
        earnContractAddress: '0xFC744521a921fEc005Fe5e91cca09878086E8526',
        pricePerFullShare: 1,
        tvl: 0,
        oracle: 'lps',
        oracleId: 'baby-bnb-usdt',
        oraclePrice: 0,
        depositsPaused: false,
        status: 'active',
        platform: 'Other',
        assets: ['BNB', 'USDT'],
        chain_icon: 'binance',
        risks: [
          'COMPLEXITY_LOW',
          'BATTLE_TESTED',
          'IL_LOW',
          'MCAP_LARGE',
          'AUDIT',
          'CONTRACTS_VERIFIED',
        ],
        stratType: 'StratLP',
        addLiquidityUrl:
          'https://exchange.babyswap.finance/#/add/0x55d398326f99059fF775485246999027B3197955/BNB',
        buyTokenUrl:
          'https://exchange.babyswap.finance/#/swap?outputcurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=BNB',
        createdAt: 1653450803,
      },
]
export const savityData: SavityType[] = [
    {
        point:'Low-complexity strategy',
        coin:'REA',
        status: 'high',
    },
    {
        point:'Strategy is battle tested',
        coin:'REA',
        status: 'high',
    },    
    {
        point:'Low expected IL',
        coin:'Asset',
        status: 'low',
    },
    {
        point:'Medium market-capitalization, average volatility asset',
        coin:'Asset',
        status: 'low',
    },
    {
        point:'Platform Audited by trusted reviewer',
        coin:'Platform',
        status: 'high',
    },
    {
        point:'Project contract are verified',
        coin:'Platform',
        status: 'high',
    },
]