import moment from "moment";
import { FC } from "react";
import { FakeTransactions } from "../../screens/Vault";
interface StakeStepFourProps {
  timeline: FakeTransactions;
}

const StakeStepFour: FC<StakeStepFourProps> = ({ timeline }) => {
  return (
    <div className="w-full text-font-size-h5">
      <div className="flex items-center mb-3 justify-between relative">
        <div className="w-[70%] font-bold">
          <span
            className={`${
              timeline.t4 ? "text-color-green-05" : "text-neutral-400"
            }`}
          >
            Funds Received:{" "}
          </span>
          <span
            className={`${
              timeline.t4 ? "text-color-support-one" : "text-neutral-400"
            }`}
          >
            TX
          </span>
        </div>
        <div className="w-[30%] font-bold">
          {timeline.t4 ? <span>{timeline.t4} sec</span> : <span>---</span>}
        </div>
      </div>
      <div className="flex items-center mb-3 justify-between relative">
        <div className="w-[70%] font-bold">
          <span
            className={`${
              timeline.t5 ? "text-color-green-05" : "text-neutral-400"
            }`}
          >
            Swapping to required tokens:{" "}
          </span>
          <span
            className={`${
              timeline.t5 ? "text-color-support-one" : "text-neutral-400"
            }`}
          >
            TX
          </span>
        </div>
        <div className="w-[30%] font-bold">
          {timeline.t5 ? <span>{timeline.t5} sec</span> : <span>---</span>}
        </div>
      </div>
      <div className="flex items-center mb-3 justify-between relative">
        <div className="w-[70%] font-bold">
          <span
            className={`${
              timeline.t6 ? "text-color-green-05" : "text-neutral-400"
            }`}
          >
            Deposit completed:{" "}
          </span>
          <span
            className={`${
              timeline.t6 ? "text-color-support-one" : "text-neutral-400"
            }`}
          >
            TX
          </span>
        </div>
        <div className="w-[30%] font-bold">
          {timeline.t6 ? <span>{timeline.t6} sec</span> : <span>---</span>}
        </div>
      </div>
      <div className="flex items-center mb-3 justify-between relative">
        <div className="w-[70%] font-bold"></div>
        <div className="w-[30%] font-bold">
          <span className="border-t-2 border-t-white py-[3px]">
            {timeline.t6 ? "1 min 10 sec" : "---"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default StakeStepFour;
