import {
    FETCH_CONNECTION_SUCCESS,
  } from "./actionTypes";
  
  import { TodoActions, WalletState } from "./types";
  
  const initialState: WalletState = {
    connected: false,
    provider: null,
    signer: null,
    address: "",
  };
  
  export default (state = initialState, action: TodoActions) => {
    switch (action.type) {
      case FETCH_CONNECTION_SUCCESS:
        return {
          ...state,
          connected: true,
          address: action.payload.address,
          signer: action.payload.signer,
          provider: action.payload.provider,
        };
      default:
        return {
          ...state,
        };
    }
  };