import React, { FC, useEffect, useState } from "react";
import CustomButton from "../components/core/CustomButton";
import GlassCard from "../components/core/GlassCard";
import CustomModal from "../components/Vault/CustomModal";
import StakeStepFour from "../components/Vault/StakeStepFour";
import StakeStepOne from "../components/Vault/StakeStepOne";
import StakeStepThree from "../components/Vault/StakeStepThree";
import StakeStepTwo from "../components/Vault/StakeStepTwo";
import TransferRow from "../components/Vault/TransferRow";
import VaultHeader from "../components/Vault/VaultHeader";
import VaultSidebar from "../components/Vault/VaultSidebar";
import { vaultData, VaultType } from "../data/data";
import { GetIconByName } from "../helpers/getIconByName";
import Content from "../assets/png/Content.png";
import { MetaMaskWalletProvider, Sdk } from "etherspot";
import SavityScore from "../components/Vault/SavityScore";
import StrategyBox from "../components/Vault/StrategyBox";
import AssetBox from "../components/Vault/AssetBox";
import HistoryChart from "../components/Vault/HistoryChart";
import { useSelector } from "react-redux";
import { getVaultsSelector } from "../redux/apy/selector";
import { useParams } from "react-router-dom";
import SendTokenImages from "../components/core/SendTokenImages";

export type FakeTransactions = {
  t1: number;
  t2: number;
  t3: number;
  t4: number;
  t5: number;
  t6: number;
};

const Vault: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<number>(0);
  const [currentVault, setCurrentVault] = useState<VaultType | null>(null);
  const [handleFakeTransaction, setHandleFakeTransaction] =
    useState<FakeTransactions>({
      t1: 0,
      t2: 0,
      t3: 0,
      t4: 0,
      t5: 0,
      t6: 0,
    });
  const pools = useSelector(getVaultsSelector);
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let item = pools?.filter((pool) => pool.id === params.id);
    setCurrentVault(item[0]);
  }, [pools, params]);

  const handleModalClose = () => {
    let BodyElement = document.querySelector("body") as HTMLElement;
    BodyElement.style.overflow = "auto";
    setHandleFakeTransaction({
      t1: 0,
      t2: 0,
      t3: 0,
      t4: 0,
      t5: 0,
      t6: 0,
    });
    setOpenModal(false);
  };

  const handleModalOpen = async (value: boolean) => {
    let element = { ...handleFakeTransaction };
    const walletProvider = await MetaMaskWalletProvider.connect();
    const sdk = new Sdk(walletProvider);
    setTimeout(() => {
      setHandleFakeTransaction({ ...element, t1: 10 });
    }, 10000);
    setTimeout(() => {
      setHandleFakeTransaction({ ...element, t1: 10, t2: 20 });
    }, 30000);
    setTimeout(() => {
      setHandleFakeTransaction({ ...element, t1: 10, t2: 20, t3: 10 });
    }, 40000);
    setTimeout(() => {
      setHandleFakeTransaction({ ...element, t1: 10, t2: 20, t3: 10, t4: 10 });
    }, 50000);
    setTimeout(() => {
      setHandleFakeTransaction({
        ...element,
        t1: 10,
        t2: 20,
        t3: 10,
        t4: 10,
        t5: 10,
      });
    }, 60000);
    setTimeout(() => {
      setHandleFakeTransaction({
        ...element,
        t1: 10,
        t2: 20,
        t3: 10,
        t4: 10,
        t5: 10,
        t6: 10,
      });
    }, 70000);
    setOpenModal(value);
  };

  return (
    <div className="min-h-[100vh] w-full px-24 py-8">
      <CustomModal modalIsOpen={openModal} handleClose={handleModalClose}>
        <GlassCard cardStyleClasses="max-w-full w-[900px] min-h-[70vh] h-auto border-color-primary">
          <div className="h-full w-full p-8 relative">
            <div className="flex justify-between items-start">
              <div className="flex justify-between items-center">
                <div className="mr-3">
                  <GetIconByName name="rea" size={48} />
                </div>
                <div className="flex flex-col">
                  <div className="text-font-size-h4 font-bold mb-1">
                    Stacking{" "}
                    {handleFakeTransaction.t6 ? "Successful" : "In-progress"}
                  </div>
                  <div className="text-color-neutral-05 text-font-size-h5">
                    STAKING DETAIL
                  </div>
                </div>
              </div>
              <div
                className="text-red-600 text-font-size-h4 cursor-pointer"
                onClick={handleModalClose}
              >
                &#10005;
              </div>
            </div>
            <div className="w-full h-[1px] my-6 bg-neutral-500"></div>
            <div className="px-2 mb-6 flex flex-col w-full relative justify-between">
              <TransferRow leftChild={<StakeStepOne />} />
              <TransferRow
                rightChild={<StakeStepTwo timeline={handleFakeTransaction} />}
              />
              <TransferRow leftChild={<StakeStepThree />} />
              <TransferRow
                rightChild={<StakeStepFour timeline={handleFakeTransaction} />}
                isLast
              />
            </div>
            <div className="flex justify-center mb-6">
              <GlassCard cardStyleClasses="w-auto px-4 py-2 mr-4 h-auto rounded-[2px]">
                <div className="w-full h-full text-font-size-h5">
                  <span>You Recieve: </span>
                  <span className="font-bold">
                    {handleFakeTransaction.t6 ? "0.123 AVAX" : "------"}
                  </span>
                </div>
              </GlassCard>
              <GlassCard cardStyleClasses="w-auto px-4 py-2 mr-4 h-auto rounded-[2px]">
                <div className="w-full h-full text-font-size-h5">
                  <span>Now You Have Total: </span>
                  <span className="font-bold">
                    {handleFakeTransaction.t6
                      ? "6.335 AVAX on Polygon"
                      : "------"}
                  </span>
                </div>
              </GlassCard>
            </div>
            <div className="flex justify-center">
              <CustomButton
                onClick={() => {}}
                text="Dashboard"
                buttonClasses="w-[200px] text-[14px] flex justify-center"
              />
            </div>
          </div>
        </GlassCard>
      </CustomModal>
      <div className="flex justify-between items-center mb-8">
        <div className="w-[70px] h-full relative mr-4">
          <SendTokenImages assets={currentVault ? currentVault.assets : ['-']} size={50} />
        </div>
        <div className="w-full flex justify-between items-center relative">
          <div className="text-font-size-text-lg font-bold">
            {currentVault?.name}
          </div>
          <div className="w-[33%] flex flex-col justify-between items-end relative">
            <div className="flex items-center mb-4">
              <div className="mr-2">
                <GetIconByName name={"binance"} size={24} />
              </div>
              <GlassCard cardStyleClasses="min-w-[100px] px-2 h-[30px] rounded-[2px] bg-gray-600">
                <div className="relative w-full h-full font-normal text-font-size-h5 flex justify-center items-center">
                  <span className="capitalize">{(currentVault && currentVault.risks)  ? currentVault.risks[0] : 'HIGH'}</span> &nbsp;
                  
                </div>
              </GlassCard>
            </div>
            <div className="font-medium flex justify-between w-full">
              <div>
                <span className="text-gray-600">CHAIN:&nbsp;</span>
                <span className="uppercase">
                  {vaultData[0].chain}&nbsp;&nbsp;
                </span>
              </div>
              <div>
                <span className="text-gray-600">PLATFORM:&nbsp;</span>
                <span className="uppercase">
                  {currentVault?.platform}&nbsp;&nbsp;
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center relative mb-8">
        <GlassCard cardStyleClasses="h-[120px] w-[65%]">
          <div className="relative w-full h-full px-8 py-6 flex flex-col justify-between items-start">
            <div className="flex justify-start items-center w-full h-full relative">
              <div className="flex flex-col w-[30%]">
                <div className="text-font-size-h5 mb-1">TVL</div>
                <div className="text-font-size-h4 font-bold">$2300</div>
              </div>
              <div className="flex flex-col w-[30%]">
                <div className="text-font-size-h5 mb-1">APY</div>
                <div className="text-font-size-h4 font-bold">$2.00</div>
              </div>
              <div className="flex flex-col w-[30%]">
                <div className="text-font-size-h5 mb-1">DAILY</div>
                <div className="text-font-size-h4 font-bold">10%</div>
              </div>
            </div>
          </div>
        </GlassCard>
        <GlassCard cardStyleClasses="h-[120px] w-[33%]">
          <div className="relative w-full h-full px-8 py-6 flex flex-col justify-between items-end">
            <div className="flex justify-end items-center w-full h-full relative">
              <div className="flex flex-col items-end w-[40%]">
                <div className="text-font-size-h5 mb-1">YOUR DEPOSIT</div>
                <div className="text-font-size-h4 font-bold">$2300</div>
              </div>
              <div className="flex flex-col items-end w-[40%]">
                <div className="text-font-size-h5 mb-1">LAST HARVEST</div>
                <div className="text-font-size-h4 font-bold">5h ago</div>
              </div>
            </div>
          </div>
        </GlassCard>
      </div>
      <div className="flex justify-between items-start relative mb-8">
        <div className="h-auto w-[65%] relative">
          <HistoryChart />
          <SavityScore />
          <StrategyBox />
          <AssetBox />
          <AssetBox />
        </div>
        <div className="h-auto w-[33%] relative">
          <GlassCard cardStyleClasses="min-h-[70vh] h-auto w-full overflow-hidden">
            <div>
              <VaultHeader
                activeSection={activeSection}
                handleActiveChange={setActiveSection}
              />
              <VaultSidebar
                activeSection={activeSection}
                handleOpenModal={handleModalOpen}
                data={currentVault}
              />
            </div>
          </GlassCard>
        </div>
      </div>
    </div>
  );
};

export default Vault;
