import { FC } from "react";
import { twMerge } from "tailwind-merge";

interface CustomButtonProps {
  text: string;
  onClick: (value?: any) => void;
  icon?: JSX.Element;
  buttonClasses?: string;
}

const CustomButton: FC<CustomButtonProps> = ({ text, onClick, icon, buttonClasses }) => {
  return (
    <div
      className={twMerge(`flex justify-between items-center px-6 py-3 bg-color-primary text-white cursor-pointer rounded-md text-[20px] font-medium`, buttonClasses)}
      onClick={onClick}
    >
      {icon && <span className="mr-2">{icon}</span>}
      <span>{text}</span>
    </div>
  );
};

export default CustomButton;
