import React, { FC, useState, useEffect } from "react";
import GlassCard from "../core/GlassCard";
import axios from "axios";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";
import {
  ValueType,
  NameType,
} from "recharts/src/component/DefaultTooltipContent";
import { getDateByFormat } from "../../helpers/getDateByFormat";

let data = [
  { name: new Date(2019, 4, 10).getTime(), price: 400, pv: 2400, amt: 2400 },
  { name: new Date(2019, 4, 11).getTime(), price: 200, pv: 2400, amt: 2400 },
  { name: new Date(2019, 4, 12).getTime(), price: 300, pv: 2400, amt: 2400 },
  { name: new Date(2019, 4, 13).getTime(), price: 300, pv: 2400, amt: 2400 },
];

const HistoryChart: FC = () => {
  const [graphData, setGraphData] = useState<number>(2);
  const [graphDate, setGraphDate] = useState<number>(1);
  const handleGraphDataSwitch = (value: number) => {
    setGraphData(value);
  };
  const handleGraphDateChange = (value: number) => {
    setGraphDate(value);
  };
  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    let item: string;
    switch (graphData) {
      case 0:
        item = "TVL";
        break;
      case 1:
        item = "Price";
        break;
      case 2:
        item = "APY";
        break;
      default:
        break;
    }
    if (active && payload && payload.length) {
      return (
        <GlassCard cardStyleClasses="backdrop-blur-lg w-[200px] h-[90px] bg-[#04061A] bg-opacity-70 rounded border-color-primary border-2 p-4">
          <div className="h-full w-full relative">
            <p className="text-font-size-h5 mb-2">{`${getDateByFormat(
              "MMM DD yyyy, hh:mm A",
              new Date(label)
            )} `}</p>
            <p className="text-font-size-h5">
              {item}: ${payload[0].value}
            </p>
          </div>
        </GlassCard>
      );
    }

    return null;
  };
  useEffect(() => {
    const getGraphData = async () => {
      let url = "https://data.beefy.finance/";
      switch (graphData) {
        case 0:
          url += "apy";
          break;
        case 1:
          url += "price";
          break;
        case 0:
          url += "tvl";
          break;
        default:
          url += "apy";
      }
      let params = {
        name: graphData === 1 ? "BIFI" : "baby-bnb-usdt",
        period: graphDate <= 1 ? "hour" : "day",
        limit: graphDate === 1 ? 216 : graphDate === 2 ? 40 : graphDate === 3 ? 395 : 30,
        from:1654659900,
        to: 1658115000
      }
      let result = await axios.get(url, {params});
    };
    getGraphData();
  }, [graphData, graphDate]);

  return (
    <GlassCard cardStyleClasses="min-h-[50vh] h-auto w-full overflow-hidden mb-8">
      <div className="h-full w-full relative">
        <div className="h-[78px] flex justify-center w-full relative  backdrop-blur-md bg-opacity-50 border border-gray-700 text-white mb-4 font-medium">
          <div className="px-8 py-2 w-full backdrop-filter bg-gray-700 bg-clip-padding text-font-size-h4 h-full flex flex-col justify-center items-start">
            Historical Rate
          </div>
          <div className="px-8 py-2 w-full relative backdrop-filter bg-gray-700 bg-clip-padding cursor-pointer text-font-size-h4 h-full flex flex-col justify-center items-start">
            <div className="w-full h-[40px] bg-gray-600 rounded relative overflow-hidden flex justify-between">
              <div
                className={`w-[33%] h-full ${
                  graphData === 0 ? "bg-color-primary " : ""
                } rounded text-base font-bold flex justify-center items-center`}
                onClick={() => handleGraphDataSwitch(0)}
              >
                TVL
              </div>
              <div
                className={`w-[33%] h-full ${
                  graphData === 1 ? "bg-color-primary " : ""
                } rounded text-base font-bold flex justify-center items-center`}
                onClick={() => handleGraphDataSwitch(1)}
              >
                PRICE
              </div>
              <div
                className={`w-[33%] h-full ${
                  graphData === 2 ? "bg-color-primary " : ""
                } rounded text-base font-bold flex justify-center items-center`}
                onClick={() => handleGraphDataSwitch(2)}
              >
                APY
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 h-[300px] w-full">
          <ResponsiveContainer width="100%" height="90%">
            <AreaChart
              width={600}
              height={300}
              data={data}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Area
                type="monotone"
                dataKey="price"
                stroke="#ffffff"
                fill="#ffffff26"
                strokeWidth={2}
              />
              {/* <CartesianGrid stroke="#fff"  /> */}
              <XAxis dataKey="name" axisLine={false} />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className="flex justify-end mb-6 px-8">
          <div className="flex justify-center items-center">
            <div
              className={`mx-4 cursor-pointer ${
                graphDate === 0 ? "font-medium" : "text-neutral-500"
              }`}
              onClick={() => handleGraphDateChange(0)}
            >
              1D
            </div>
            <div
              className={`mx-4 cursor-pointer ${
                graphDate === 1 ? "font-medium" : "text-neutral-500"
              }`}
              onClick={() => handleGraphDateChange(1)}
            >
              1W
            </div>
            <div
              className={`mx-4 cursor-pointer ${
                graphDate === 2 ? "font-medium" : "text-neutral-500"
              }`}
              onClick={() => handleGraphDateChange(2)}
            >
              1M
            </div>
            <div
              className={`mx-4 cursor-pointer ${
                graphDate === 3 ? "font-medium" : "text-neutral-500"
              }`}
              onClick={() => handleGraphDateChange(3)}
            >
              1Y
            </div>
          </div>
        </div>
      </div>
    </GlassCard>
  );
};

export default HistoryChart;
