import Web3Modal from "web3modal";
import { ethers } from "ethers";

const providerOptions = {};

export function indexBy(array, key) {
    return Object.fromEntries(array.map(item => [item[key], item]));
  }

  export const connectWallet = async () => {
    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const instance = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      const sng = provider.getSigner();
      const address = await sng.getAddress();
      // const walletProvider = await MetaMaskWalletProvider.connect();
      // const sdk = new Sdk(walletProvider);

      return [provider, sng, address];
    } catch (error) {
      console.log(error);
    }
  };

export const selectedChainId = 137;