import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { convertAmountToRawNumber } from "./bignumber";
import { beefyUniV2ZapABI, uniswapV2RouterABI } from "./configure";

export const zapDepositEstimate = async ({
  signer,
  zapAddress,
  vaultAddress,
  tokenAddress,
  tokenAmount,
}) => {
  const contract = new ethers.Contract(zapAddress, beefyUniV2ZapABI, signer);
  console.log("Zap function");
  return await contract.estimateSwap(vaultAddress, tokenAddress, tokenAmount);
  // { swapAmountIn uint256, swapAmountOut uint256, swapTokenOut address }
};

export async function fetchZapWithdrawEstimate({
  signer,
  vaultAddress,
  routerAddress,
  swapInput,
  swapOutput,
  pairToken,
  pairTokenAmount,
}) {
  const totalSupply = new BigNumber(pairToken.totalSupply);
  const shares = new BigNumber(pairTokenAmount);
  if (shares.isZero()) {
    return {
      swapEstimate: {
        amountIn: 0,
        amountOut: 0,
        swapInput,
        swapOutput,
      },
    };
  } else {
    const equity = shares.dividedBy(totalSupply);
    let amountIn, reserveIn, reserveOut;
    if (swapInput.address.toLowerCase() === pairToken.token0.toLowerCase()) {
      amountIn = equity.multipliedBy(pairToken.reserves[0]);
      reserveIn = pairToken.reserves[0];
      reserveOut = pairToken.reserves[1];
    }
    if (swapInput.address.toLowerCase() === pairToken.token1.toLowerCase()) {
      amountIn = equity.multipliedBy(pairToken.reserves[1]);
      reserveIn = pairToken.reserves[1];
      reserveOut = pairToken.reserves[0];
    }

    amountIn = convertAmountToRawNumber(amountIn, 0);

    const res = await zapWithdrawEstimate({
      signer,
      routerAddress,
      amountIn,
      reserveIn,
      reserveOut,
    });
    return res;
  }
}

export const zapWithdrawEstimate = async ({ signer, routerAddress, amountIn, reserveIn, reserveOut }) => {
    const contract = new ethers.Contract(routerAddress, uniswapV2RouterABI ,signer);
    return await contract.getAmountOut(amountIn, reserveIn, reserveOut);
  };