import { FC } from "react";
import { FakeTransactions } from "../../screens/Vault";
import moment from "moment";

interface StakeStepTwoProps {
  timeline: FakeTransactions;
}

const StakeStepTwo: FC<StakeStepTwoProps> = ({ timeline }) => {
  return (
    <div className="w-full text-font-size-h5">
      <div className="flex items-center mb-3 justify-between relative">
        <div className="w-[70%] font-bold">
          <span
            className={`${
              timeline.t1 ? "text-color-green-05" : "text-neutral-400"
            }`}
          >
            Withdrawal completed:{" "}
          </span>
          <span
            className={`${
              timeline.t1 ? "text-color-support-one" : "text-neutral-400"
            }`}
          >
            TX
          </span>
        </div>
        <div className="w-[30%] font-bold">
          {timeline.t1 ? (
            <span>
              {timeline.t1} sec
            </span>
          ) : (
            <span>---</span>
          )}
        </div>
      </div>
      <div className="flex items-center mb-3 justify-between relative">
        <div className="w-[70%] font-bold">
          <span
            className={`${
              timeline.t2 ? "text-color-green-05" : "text-neutral-400"
            }`}
          >
            Approved:{" "}
          </span>
          <span
            className={`${
              timeline.t2 ? "text-color-support-one" : "text-neutral-400"
            }`}
          >
            TX
          </span>
        </div>
        <div className="w-[30%] font-bold">
          {timeline.t2 ? (
            <span>
              {timeline.t2} sec
            </span>
          ) : (
            <span>---</span>
          )}
        </div>
      </div>
      <div className="flex items-center mb-3 justify-between relative">
        <div className="w-[70%] font-bold">
          <span
            className={`${
              timeline.t3 ? "text-color-green-05" : "text-neutral-400"
            }`}
          >
            Bridging started:{" "}
          </span>
          <span
            className={`${
              timeline.t3 ? "text-color-support-one" : "text-neutral-400"
            }`}
          >
            TX
          </span>
        </div>
        <div className="w-[30%] font-bold">
          {timeline.t3 ? (
            <span>
              {timeline.t3} sec
            </span>
          ) : (
            <span>---</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default StakeStepTwo;
