import React, { FC } from "react";
import GlassCard from "../core/GlassCard";
import { ReactComponent as LinkIcon } from "../../assets/svg/icons/link.svg";
import { ReactComponent as TwoArrow } from "../../assets/svg/icons/two-arrow.svg";
import { ReactComponent as ShieldIcon } from "../../assets/svg/icons/shield.svg";

const StrategyBox: FC = () => {
  return (
    <GlassCard cardStyleClasses="min-h-[40vh] h-auto w-full overflow-hidden mb-8">
      <div>
        <div className="h-[78px] flex justify-center w-full relative  backdrop-blur-md bg-opacity-50 border border-gray-700 text-white mb-4 font-medium">
          <div className="px-8 py-2 w-full backdrop-filter bg-gray-700 bg-clip-padding cursor-pointer text-font-size-h4 h-full flex flex-col justify-center items-start">
            <div className="flex justify-end items-start">
              <span className="mr-2 text-font-size-h4 font-bold">Strategy</span>
            </div>
            <div className="uppercase text-font-size-h5 text-neutral-400 flex justify-start">
              <div className="flex justify-center items-center mr-8">
                <span className="mr-2">
                  <TwoArrow />
                </span>
                <span>Strategy Address</span>
                <span className="ml-2">
                  <LinkIcon />
                </span>
              </div>
              <div className="flex justify-center items-center mr-8">
                <span className="mr-2">
                  <TwoArrow />
                </span>
                <span>Vault Adress</span>
                <span className="ml-2">
                  <LinkIcon />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="px-8 text-font-size-h6 text-neutral-500 py-4 pr-[25%]">
          <div className="mb-8">
            The vault deposits the user's BIFI-ONE LP in a SushiSwap farm,
            earning the platform's governance token. Earned token is swapped for
            BIFI and ONE in order to acquire more of the same LP token. To
            complete the compounding cycle, the new BIFI-ONE LP is added to the
            farm, ready to go for the next earning event. The transaction cost
            required to do all this is socialized among the vault's users.
          </div>
          <div>
            <div className="mb-2 text-base font-medium text-white">
              APY Breakdown
            </div>
            <div className="flex justify-between items-start">
              <div className="flex justify-start">
                <div className="mr-8">
                  <div>TOTAL APY</div>
                  <div className="mb-2 text-base font-medium text-white">
                    6.18%
                  </div>
                </div>
                <div className="mr-4">
                  <div>Vault APR</div>
                  <div className="mb-2 text-base font-medium text-white">
                    6.18%
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex items-center mr-8">
                  <span className="mr-2">
                    <ShieldIcon />
                  </span>
                  <span className="text-base font-medium text-white">
                    Audited
                  </span>
                </div>
                <div className="flex items-center mr-8">
                  <span className="mr-2">
                    <ShieldIcon />
                  </span>
                  <span className="text-base font-medium text-white">
                    Community Audit
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GlassCard>
  );
};

export default StrategyBox;
