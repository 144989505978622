import {
  FETCH_APY_REQUEST,
  FETCH_APY_SUCCESS,
  FETCH_APY_FAILURE,
  FETCH_VAULT_SUCCESS,
  FETCH_TOKENS_SUCCESS,
} from "./actionTypes";

import { ApyActions, ApyState } from "./types";

const initialState: ApyState = {
  pending: false,
  apys: [],
  vaults: [],
  error: null,
  tokens: {},
};

export default (state = initialState, action: ApyActions) => {
  switch (action.type) {
    case FETCH_APY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_APY_SUCCESS:
      return {
        ...state,
        pending: false,
        apys: action.payload.apys,
        error: null,
      };
    case FETCH_APY_FAILURE:
      return {
        ...state,
        pending: false,
        apys: [],
        error: action.payload.error,
      };
    case FETCH_VAULT_SUCCESS:
      return {
        ...state,
        vaults: action.payload,
      };
    case FETCH_TOKENS_SUCCESS:
      return {
        ...state,
        tokens: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
