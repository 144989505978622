import { BigNumberish, ethers, utils, Wallet } from "ethers";
import {
  BridgingQuotes,
  CrossChainServiceProvider,
  Env,
  EnvNames,
  MetaMaskWalletProvider,
  NetworkNames,
  NETWORK_NAME_TO_CHAIN_ID,
  Sdk,
} from "etherspot";
import React, { FC, useEffect, useMemo, useState } from "react";
import { vaultData, VaultType } from "../../data/data";
import { GetIconByName } from "../../helpers/getIconByName";
import CustomButton from "../core/CustomButton";
import CustomInput from "../core/CustomInput";
import GlassCard from "../core/GlassCard";
import VaultList from "./VaultList";
import ZapTokenList from "./ZapTokenList";
import SendTokenImages from "../core/SendTokenImages";
import { selectedChainId } from "../../helpers/util";
import { useSelector } from "react-redux";
import { getAddressSelector, getProviderSelector, getSignerSelector } from "../../redux/wallet/selector";
import { approval, deposit } from "../../helpers/depositFunctions";
import { zapDepositEstimate } from "../../helpers/zapEstimate";
import { zapDeposit } from "../../helpers/zapDeposit";
import BigNumber from "bignumber.js";
import { getNetworkCoin } from "../../helpers/base";
import { getTokensSelector } from "../../redux/apy/selector";
import { byDecimals } from "../../helpers/bignumber";
import { BeefyUniV2ZapContract, ERC20Contract, VaultContract } from "../../helpers/contracts";

interface VaultSidebarProps {
  handleOpenModal: (value: any) => void;
  activeSection: number;
  data?: VaultType;
}

const VaultSidebar: FC<VaultSidebarProps> = ({
  handleOpenModal,
  activeSection,
  data,
}) => {
  const provider= useSelector(getProviderSelector);
  const signer = useSelector(getSignerSelector);
  const address = useSelector(getAddressSelector);
  const nativeCoin = getNetworkCoin(selectedChainId);
  const tokens = useSelector(getTokensSelector);


  const balanceSingle = byDecimals(
    tokens[data?.token]?.tokenBalance,
    data?.tokenDecimals
  );
  let balance2;
  if(tokens[data?.earnedToken]?.launchpoolTokenBalance){
    let num = new BigNumber(0)
    balance2 = BigNumber.sum(tokens[data?.earnedToken]?.launchpoolTokenBalance,tokens[data?.earnedToken]?.tokenBalance)
    console.log("BALANCE2", balance2)
  }
  const sharesBalance = new BigNumber(tokens[data?.earnedToken]?.tokenBalance);
  const deposited = byDecimals(
    sharesBalance.multipliedBy(new BigNumber(data?.pricePerFullShare)),
    data?.tokenDecimals
  );
  const sharesDecimals = data?.tokenDecimals;
  const sharesByDecimals = byDecimals(sharesBalance, sharesDecimals);
  const underliyngBalance = sharesByDecimals
    .multipliedBy(data?.pricePerFullShare)
    .decimalPlaces(data?.tokenDecimals, BigNumber.ROUND_DOWN);


  const [selectedToken, setSelectedToken] = useState<any>();
  const [vaultCoin, setVaultCoin] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [vaultTokens, setVaultTokens] = useState<any>();
  const [withdrawToken, setWithdrawToken] = useState<any>()
  const [eligableToken, setEligableToken] = useState<any>()
  const [currentVault, setCurrentVault] = useState<VaultType | null>()
  const [withdrawSettings, setWithdrawSettings] = useState({
    isZap: false,
    isSwap: false,
    swapInput: undefined,
    swapOutput: undefined,
    outputIndex: 0,
    amount: new BigNumber(0),
    slider: 0,
    input: '0.0',
    vaultAddress: data?.earnContractAddress,
    withdrawAddress: data?.earnContractAddress,
    isNeedApproval: false,
    slippageTolerance: 0.01,
    swapAmountOut: data?.zapWithdrawEstimate?.swapAmountOut,
  });
  const withdrawOutputs = useMemo(() => {
    const outputs: any = [
      {
        name: data?.name,
        symbol: data?.token,
        address: data?.tokenAddress,
        decimals: data?.tokenDecimals,
        token: data?.assets
      },
    ];

    if (data && data.zap) {
      let pairTokens = data.zap.tokens.filter(t => t.symbol !== nativeCoin.wrappedSymbol);
      pairTokens = pairTokens.map(pairToken => {
        return {...pairToken, token: [pairToken.symbol]}
      } )
      if (pairTokens.length) {
        outputs.push(
          {
            symbol: data.assets.join('+'),
            token: [...data.assets],
          },
          ...pairTokens
        );
      }
    }

    return outputs;
  }, [data?.assets, data?.name, data?.token, data?.tokenAddress, data?.tokenDecimals, data?.zap]);
  console.log("WITHDRAWOUTP",underliyngBalance,sharesByDecimals,  sharesBalance, data?.pricePerFullShare, data?.tokenDecimals)
  console.log("BALANCE3",sharesBalance,tokens[data?.earnedToken])

  const handleAmountChange = (e: any) => {
    setAmount(Number(e.target.value));
  };

  const handleDeposit = async () => {
    const walletProvider = await MetaMaskWalletProvider.connect();
    if (!window.ethereum) return undefined;
    let chainId = ethers.utils.hexValue(selectedChainId);
    console.log(chainId);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
      window.ethereum.on('chainChanged', chainId => {
        // handle the new network
      })
    } catch (error) {
      alert("Some Error occured");
    }

    const sdk = new Sdk(walletProvider, {
      env: EnvNames.MainNets,
      // networkName: NetworkNames.Matic,
    });

    const { state } = sdk;

    console.log("key account", state.account);

    let contractAccount;
    try {
      contractAccount = await sdk.computeContractAccount({
        sync: false,
      });
    } catch (error) {
      console.log("Error", error)
    }
    console.log("contract account", contractAccount);

    await sdk.syncAccount();

    // Build the approval transaction request
    const erc20Contract = new ERC20Contract(selectedToken.tokenAddress);
    await sdk.clearGatewayBatch();
    let amt = ethers.utils.parseEther("0.01")

    if (selectedToken.name !== currentVault.name) {
      const swapAmountOut = currentVault.zapEstimate.swapAmountOut;
      const swapAmountOutMin = new BigNumber(
        swapAmountOut - swapAmountOut * 0.01
      );
      const beefyUniV2ZapContract = new BeefyUniV2ZapContract(currentVault.zap.zapAddress);
      if (!!selectedToken.wrappedSymbol) {
        const encodedBeefInETH = beefyUniV2ZapContract.encodeBeefInETH(
          currentVault.earnContractAddress,
          swapAmountOutMin.toFixed(0)
        );
        await sdk.batchExecuteAccountTransaction({
          ...encodedBeefInETH,
          value: swapAmountOutMin.toFixed(0) // value is needed for native assets
        })
      } else {
        await sdk.batchExecuteAccountTransaction(
          erc20Contract.encodeApprove(
            beefyUniV2ZapContract.address,
            amt
          )
        );
        await sdk.batchExecuteAccountTransaction(
          beefyUniV2ZapContract.encodeBeefIn(
            currentVault.earnContractAddress,
            swapAmountOutMin.toFixed(0),
            selectedToken.tokenAddress,
            amt
          )
        );
      }
    } else {
      const vaultContract = new VaultContract(data.earnContractAddress);
      // TODO: add check for existing approval (erc20Contract.callAllowance)
      await sdk.batchExecuteAccountTransaction(
        erc20Contract.encodeApprove(
          vaultContract.address,
          ethers.constants.MaxUint256
        )
      );
      if (Boolean(currentVault.tokenAddress) && Boolean(selectedToken.name !== currentVault.name)) {
        await sdk.batchExecuteAccountTransaction(
          vaultContract.encodeDepositAll()
        );
      } else {
        await sdk.batchExecuteAccountTransaction(
          vaultContract.encodeDeposit(
            ethers.BigNumber.from(amt)
          )
        );
      }
    }

    let estimatedGas;
    try {
      estimatedGas = await sdk.estimateGatewayBatch();
    } catch (error) {
      console.log('Failed to estimate', await sdk.encodeGatewayBatch())
      alert('Failed to estimate');
    }
    // Estimate and submit the transactions to the Gateway
    console.log(
      "estimated batch",
      utils.formatEther(estimatedGas.estimation.feeAmount)
    );
    console.log("submitted batch", await sdk.submitGatewayBatch());
  };

  const handleDeposit2 = async () => {
    const walletProvider = await MetaMaskWalletProvider.connect();
    if (!window.ethereum) return undefined;
    let chainId = ethers.utils.hexValue(selectedChainId);
    console.log(chainId);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
      window.ethereum.on('chainChanged', chainId => {
        // handle the new network
      })
    } catch (error) {
      alert("Some Error occured");
    }

    const sdk = new Sdk(walletProvider, {
      env: EnvNames.MainNets,
    });

    const { state } = sdk;

    console.log("key account", state);
    console.log("key account", state.account);

    let contractAccount;
    try {
      contractAccount = await sdk.computeContractAccount({
        sync: false,
      });
    } catch (error) {
      console.log("Error", error)
    }
    console.log("contract account", contractAccount);

    await sdk.syncAccount();

    try {
      let apporval = await approval({
        address:address,
        tokenAddress:selectedToken.tokenAddress,
        contractAddress:data.earnContractAddress,
        signer:signer,
        amount:"10000000000000000"
      })
      console.log("Approval",apporval);
    } catch (error) {
      console.log("Approval",error);
    }

    // for is zaps
    if(selectedToken.name !== currentVault.name){
      const swapAmountOut = currentVault.zapEstimate.swapAmountOut;
      const swapAmountOutMin = new BigNumber(
        swapAmountOut - swapAmountOut * 0.01
      );
      try {
        let deposit = await zapDeposit(
          {
            isETH: !!selectedToken.wrappedSymbol,
            signer,
            swapAmountOutMin: swapAmountOutMin.toFixed(0),
            vaultAddress: currentVault.earnContractAddress,
            zapAddress: currentVault.zap.zapAddress,
            address,
            tokenAddress:selectedToken.tokenAddress,
            tokenAmount:"10000000000000000",
          }
        )
        console.log(deposit, "DEPOSIT ZAP")
      } catch (error) {
        console.log("DEPOSIT ZAP",error)
      }
    }else{
      try {
        let res = await deposit({
          address:address,
          amount:"10000000000000000",
          contractAddress:data.earnContractAddress,
          isAll: (Boolean(currentVault.tokenAddress) && Boolean(selectedToken.name !== currentVault.name)) ? true : false,
          signer:signer,
        })
        console.log("Deposit test",res)
      } catch (error) {
        console.log("Deposit test:Error",error)
        
      }
    }
  }
  
  const handleWithdraw = async () => {
    console.log("Amount");
  }

  const handleSelect = (item) => {
    setSelectedToken(item);
  };
  const handleSelectWithdraw = (item) => {
    setWithdrawToken(item);
  };
  const handleInputChange = (e) => {
    const input = e.target.value
    let amount = new BigNumber(input);

    if (amount.isNaN()) amount = new BigNumber(0);
    if (amount.isGreaterThan(underliyngBalance)) amount = underliyngBalance;

    const sliderInt = underliyngBalance.isZero()
      ? 0
      : amount.times(100).dividedToIntegerBy(underliyngBalance).toNumber();

    setWithdrawSettings(prevState => ({
      ...prevState,
      amount: amount,
      slider: sliderInt,
      input: amount.isEqualTo(input) ? input : amount.toFormat(),
    }));
  }
  useEffect(() => {
    if (data) {
      console.log(data);
      let tokens = [
        {
          id: data.id,
          name: data.name,
          tokenAddress: data.tokenAddress,
          token: [...data.assets],
        },
      ];
      setEligableToken({...tokens[0],decimals: data.tokenDecimals})
      setSelectedToken(tokens[0]);
      if (data.zap) {
        data.zap.tokens.forEach((element, index) => {
          tokens.push({
            ...element,
            id: String(element.name) + "_" + index,
            name: String(element.symbol),
            tokenAddress: element.address,
            token: [element.symbol],
          });
        });
      }
      console.log("KAJA", tokens);
      setVaultTokens(tokens);
    }
    
  }, [data]);
  useEffect(() => {
    const getZapEstimate = async () => {
      
      try {
        let zapEstimate = await zapDepositEstimate({
          signer,
          zapAddress: data.zap.zapAddress,
          vaultAddress: data.earnContractAddress,
          tokenAmount:"10000000000000000",
          tokenAddress:selectedToken.tokenAddress,
        })
        console.log("Zap Estimate:",zapEstimate, data);
        setCurrentVault({...data, zapEstimate})
      } catch (error) {
        setCurrentVault({...data})
      }
    }
    if(selectedToken && data)
    getZapEstimate();
  }, [selectedToken,data])
  

  return (
    <div className="p-4 px-6 min-h-[300px]">
      {activeSection === 0 && (
        <>
          <div className="text-[17px] font-bold text-left mb-4">
            My Vault - Cross-Chain From Your Vault
          </div>
          <div className="w-full flex flex-col mb-2">
            {vaultTokens?.map((item, index) => {
              return (
                <ZapTokenList
                  data={item}
                  key={index}
                  handleSelect={handleSelect}
                  selected={selectedToken?.id === item.id}
                />
              );
            })}
          </div>
          <div className="text-[18px] font-bold text-left mb-4">
            Cross-Chain to Vault
          </div>
          <div>
            <div className="text-color-neutral-05 text-font-size-h5 mb-2">
              FROM
            </div>
            <div className="mb-6">
              <div className="w-full flex items-center relative mb-6">
                <div className="relative w-[40px] mr-4">
                  <SendTokenImages
                    size={28}
                    assets={
                      selectedToken?.token ? selectedToken?.token : "ethereum"
                    }
                  />
                </div>
                <div className="text-font-size-h5 font-bold">
                  {selectedToken ? selectedToken.name : "-"}
                </div>
              </div>
              <div>
                <CustomInput
                  onChange={handleAmountChange}
                  placeholder="Enter Amount"
                  inputClasses="w-full border-2 rounded-md"
                  value={amount}
                  type="number"
                  iconRight={
                    <div className="text-font-size-text-sm px-1 py-1 rounded border-[1px] bg-slate-800">
                      MAX
                    </div>
                  }
                />
              </div>
            </div>
            <div className="text-color-neutral-05 text-font-size-h5 mb-2">
              TO
            </div>
            <div className="mb-4">
              <div>
                <CustomInput
                  onChange={() => {}}
                  placeholder="Enter Amount"
                  inputClasses="w-full border-2 rounded-md"
                  icon={
                    <SendTokenImages
                      size={22}
                      assets={data ? data.assets : ["ethereum"]}
                    />
                  }
                  value={data ? data.name : "-"}
                  disabled
                />
              </div>
            </div>
            <CustomButton
              // onClick={handleOpenModal}
              onClick={handleDeposit}
              text="Deposit"
              buttonClasses="text-center justify-center"
            />
          </div>
        </>
      )}
      {activeSection === 1 && (
        <>
          <div className="flex justify-center mb-8">
            <div className="w-[200px] h-[48px] overflow-hidden rounded flex justify-center bg-[rgba(255,255,255,0.1)] relative">
              <div
                className={`w-1/2 h-full cursor-pointer flex justify-center items-center font-bold rounded ${
                  vaultCoin === 0 ? "bg-color-primary" : ""
                }`}
                onClick={() => setVaultCoin(0)}
              >
                Coin
              </div>
              <div
                className={`w-1/2 h-full cursor-pointer flex justify-center items-center font-bold rounded ${
                  vaultCoin === 1 ? "bg-color-primary" : ""
                }`}
                onClick={() => setVaultCoin(1)}
              >
                Vault
              </div>
            </div>
          </div>
          <div className="mb-8">
          {withdrawOutputs?.map((item, index) => {
              return (
                <ZapTokenList
                  data={item}
                  key={index}
                  handleSelect={handleSelectWithdraw}
                  selected={withdrawToken?.symbol === item.symbol}
                  displayKey={"symbol"}
                />
              );
            })}
          </div>
          <div className="mb-8">
            <CustomInput
              onChange={handleInputChange}
              placeholder="Enter Amount"
              inputClasses="w-full border-2 rounded-md"
              icon={<GetIconByName name="rea" size={16} />}
              value="0"
              iconRight={
                <div className="text-font-size-text-sm px-1 py-1 rounded border-[1px] bg-slate-800">
                  MAX
                </div>
              }
              type="number"
            />
          </div>
          <div className="mb-8">
            <div className="mb-4 text-font-size-h5">DEPOSITED</div>
            <GlassCard cardStyleClasses="min-h-[250px] p-4">
              <div className="h-full w-full relative">
                <div className="font-medium mb-4">REA Fees</div>
                <div className="flex w-full relative mb-4">
                  <div className="w-1/2">
                    <div className="text-font-size-h6 text-color-neutral-05 mb-2">
                      DEPOSIT FEE
                    </div>
                    <div className="font-medium">0%</div>
                  </div>
                  <div className="w-1/2">
                    <div className="text-font-size-h6 text-color-neutral-05 mb-2">
                      WITHDRAWL FEE
                    </div>
                    <div className="font-medium">1%</div>
                  </div>
                </div>
                <div className="flex w-full relative mb-4">
                  <div className="w-1/2">
                    <div className="text-font-size-h6 text-color-neutral-05 mb-2">
                      PERFORMANCE FEE
                    </div>
                    <div className="font-medium">1%</div>
                  </div>
                </div>
                <div className="text-font-size-h6 text-color-neutral-05 mb-2">
                  Performance fees are already subtracted from the displayed APY
                </div>
              </div>
            </GlassCard>
          </div>
          <CustomButton
            onClick={handleWithdraw}
            text="Withdraw"
            buttonClasses="text-center justify-center"
          />
        </>
      )}
    </div>
  );
};

export default VaultSidebar;

// const data = "0x095ea7b30000000000000000000000009f4316c10d5c3f148f072f6fa7e81413b7da11a7000000000000000000000000000000000000000000000000002386f26fc10000"
// const hex = "0xf5d07b600000000000000000000000009f4316c10d5c3f148f072f6fa7e81413b7da11a7000000000000000000000000000000000000000000000000000342a268e381970000000000000000000000000d500b1d8e8ef31e21c99d1db9a6444d3adf1270000000000000000000000000000000000000000000000000002386f26fc10000"

// const sdkHex = "0xf5d07b600000000000000000000000009f4316c10d5c3f148f072f6fa7e81413b7da11a700000000000000000000000000000000000000000000000000035a7de0b69d740000000000000000000000000d500b1d8e8ef31e21c99d1db9a6444d3adf127000000000000000000000000000000000000000000000000000470de4df820000"