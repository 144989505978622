import React, { FC } from "react";
import { CategoryType } from "./Categories";


interface CategoryItemProps {
  onChange: (value: any) => void;
  isSelected: boolean;
  item: CategoryType;
}

const CategoryItem: FC<CategoryItemProps> = ({
  onChange,
  isSelected,
  item,
}) => {
  return (
    <div
      className={`text-font-size-h5 cursor-pointer text-white font-bold w-full flex justify-center items-center h-full ${
        isSelected ? "bg-color-primary" : "bg-transparent"
      }`}
      onClick={() => onChange(item.value)}
    >
      {item.name}
    </div>
  );
};

export default CategoryItem;
