import React from "react";
import { twMerge } from "tailwind-merge";

interface GlassCardProps {
  cardStyleClasses?: string;
  children: JSX.Element;
}

const GlassCard = ({ cardStyleClasses, children }: GlassCardProps) => {
  return (
    <div
      className={twMerge(
        "h-[100px] w-full bg-[#323446] rounded-md bg-clip-padding relative backdrop-filter backdrop-blur-md bg-opacity-40 border border-gray-500 text-white",
        cardStyleClasses
      )}
    >
      {children}
    </div>
  );
};

export default GlassCard;
