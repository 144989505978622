import React, { FC } from "react";
import { ReactComponent as PositiveIcon } from "../../assets/svg/icons/positive.svg";
import { ReactComponent as NegativeIcon } from "../../assets/svg/icons/negative.svg";

interface PointProps {
  status: "high" | "low";
  coin: string;
  point: string;
}

const Point: FC<PointProps> = ({ status, coin, point }) => {
  return (
    <div className="relative flex justify-start items-start mb-3">
      <div className="w-[20px] flex justify-start items-start mr-4 pt-[5px]">
        {status === "high" ? (
          <span className="">
            <PositiveIcon />
          </span>
        ) : (
          <span className="">
            <NegativeIcon />
          </span>
        )}
      </div>
      <div>
        <div className="text-font-size-h5 font-bold mb-2">{point}</div>
        <div className="text-font-size-h6 text-neutral-500">{coin}</div>
      </div>
    </div>
  );
};

export default Point;
