import { MetaMaskWalletProvider, Sdk } from "etherspot";
import { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GlassCard from "../components/core/GlassCard";
import Categories, { CategoryType } from "../components/Home/Categories";
import IconList from "../components/Home/IconList";
import VaultCard from "../components/Home/VaultCard";
import { VaultType } from "../data/data";
import { fetchVaultsData, getEligibleZap } from "../helpers/base";
import { bscPools } from "../helpers/configure";
import { getTokens } from "../helpers/getTokens";
import { getApysSelector, getTokensSelector, getVaultsSelector } from "../redux/apy/selector";
import { useDispatch } from "react-redux";
import { FETCH_TOKENS_SUCCESS, FETCH_VAULT_SUCCESS } from "../redux/apy/actionTypes";
import { connectWallet, selectedChainId } from "../helpers/util";
import { getProviderSelector, getSignerSelector } from "../redux/wallet/selector";

const Home: FC = () => {
  const iconList = [
    "ethereum",
    "avalanche",
    "binance",
    "xdai",
    "terra",
    "near",
    "fantom",
    "polygon",
  ];
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [selectedIcons, setSelectedIcons] = useState<string[]>([...iconList]);
  const [vaultsByChain, setVaultsByChain] = useState<Array<VaultType | null>>(
    []
  );
  const handleChainSelection = (selected: string) => {
    let arr: string[] = [...selectedIcons];
    if (selectedIcons.includes(selected)) {
      arr = selectedIcons.filter((i) => i !== selected);
    } else {
      arr.push(selected);
    }
    setSelectedIcons(arr);
  };
  const categories: CategoryType[] = [
    {
      name: "All",
      value: 0,
    },
    {
      name: "Featured",
      value: 1,
    },
    {
      name: "Stablecoins",
      value: 2,
    },
    {
      name: "Blue Chip",
      value: 3,
    },
  ];
  const filters: CategoryType[] = [
    {
      name: "All Vaults",
      value: 0,
    },
    {
      name: "Eligable Vaults",
      value: 1,
    },
    {
      name: "My Vaults",
      value: 2,
    },
  ];
  const apys = useSelector(getApysSelector);
  const pools = useSelector(getVaultsSelector);
  const tokens = useSelector(getTokensSelector);
  const provider= useSelector(getProviderSelector);
  const signer = useSelector(getSignerSelector);
  useEffect(() => {
    console.log(pools, "LAJAJ")
  }, [pools])

  return (
    <div className="min-h-[100vh] w-full px-24 py-8">
      <div className="flex justify-center items-center relative mb-8">
        <GlassCard cardStyleClasses="h-[170px] w-1/2 rounded-tr-none rounded-br-none">
          <div className="relative w-full h-full px-8 py-6 flex flex-col justify-between items-start">
            <div className="text-font-size-text-lg font-semibold">
              Portfolio
            </div>
            <div className="flex justify-start items-center w-full relative">
              <div className="flex flex-col w-[30%]">
                <div className="text-font-size-h5 mb-1">DEPOSITED</div>
                <div className="text-font-size-h4 font-bold">$2300</div>
              </div>
              <div className="flex flex-col w-[30%]">
                <div className="text-font-size-h5 mb-1">MONTHLY YIELD</div>
                <div className="text-font-size-h4 font-bold">$2.00</div>
              </div>
              <div className="flex flex-col w-[30%]">
                <div className="text-font-size-h5 mb-1">Daily YIELD</div>
                <div className="text-font-size-h4 font-bold">$2.00</div>
              </div>
            </div>
          </div>
        </GlassCard>
        <GlassCard cardStyleClasses="h-[170px] w-1/2 rounded-tl-none rounded-bl-none">
          <div className="relative w-full h-full px-8 py-6 flex flex-col justify-between items-end">
            <div className="text-font-size-text-lg font-semibold">Platform</div>
            <div className="flex justify-end items-center w-full relative">
              <div className="flex flex-col items-end w-[30%]">
                <div className="text-font-size-h5 mb-1">TVL</div>
                <div className="text-font-size-h4 font-bold">$2300</div>
              </div>
              <div className="flex flex-col items-end w-[30%]">
                <div className="text-font-size-h5 mb-1">VAULT</div>
                <div className="text-font-size-h4 font-bold">$2.00</div>
              </div>
              <div className="flex flex-col items-end w-[30%]">
                <div className="text-font-size-h5 mb-1">DAILY BUYBACK</div>
                <div className="text-font-size-h4 font-bold">$2.00</div>
              </div>
            </div>
          </div>
        </GlassCard>
      </div>
      <div className="h-[1px] w-full bg-color-neutral-05 mb-8"></div>
      <div className="flex justify-between relative w-full mb-8">
        <Categories
          title="CATEGORIES"
          options={categories}
          selectedCategory={selectedCategory}
          onChange={setSelectedCategory}
        />
        <Categories
          title="FILTERS"
          options={filters}
          selectedCategory={selectedFilter}
          onChange={setSelectedFilter}
        />
      </div>
      <div className="flex justify-center relative w-full mb-12 gap-4">
        {iconList.map((icon, index) => (
          <IconList
            name={icon}
            key={index}
            onClick={handleChainSelection}
            isSelected={selectedIcons.includes(icon)}
          />
        ))}
      </div>
      <div className="relative flex flex-col items-start w-full">
        {pools &&
          pools?.map((vaultData, index) => (
            <VaultCard
              key={index}
              data={vaultData}
              tokens={tokens}
              apy={apys[vaultData.id] || { totalApy: 0 }}
            />
          ))}
      </div>
    </div>
  );
};

export default Home;
