import React, { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "../constants/routes";
import Home from "../screens/Home";
import Vault from "../screens/Vault";

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path={routes.home.path} element={<Home />} />
      <Route path={routes.vault.path} element={<Vault />} />
      <Route path="/" element={<Navigate to={routes.home.path} replace />} />
    </Routes>
  );
};

export default AppRoutes;
