import { createSelector } from "reselect";

import { AppState } from "../rootReducer";

const getPending = (state: AppState) => state.apy.pending;

const getApys = (state: AppState) => state.apy.apys;

const getError = (state: AppState) => state.apy.error;

const getVaults = (state: AppState) => state.apy.vaults;

const getTokens = (state: AppState) => state.apy.tokens;

export const getApysSelector = createSelector(getApys, (apys) => apys);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getVaultsSelector = createSelector(getVaults, (vaults) => vaults);

export const getTokensSelector = createSelector(getTokens, (tokens) => tokens);
