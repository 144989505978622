import React, { FC } from "react";
interface VaultHeaderProps {
  activeSection: number;
  handleActiveChange: (value: any) => void;
}

const VaultHeader: FC<VaultHeaderProps> = ({
  activeSection,
  handleActiveChange,
}) => {
  return (
    <div className="h-[78px] flex justify-center w-full relative  backdrop-blur-md bg-opacity-50 border border-gray-700 text-white mb-4 font-medium">
      <div
        className={`w-1/2 backdrop-filter ${
          activeSection === 0 ? "bg-gray-600 bg-clip-padding" : ""
        }  cursor-pointer text-font-size-h4 h-full flex justify-center items-center`}
        onClick={() => handleActiveChange(0)}
      >
        Deposit
      </div>
      <div
        className={`w-1/2 backdrop-filter ${
          activeSection === 1 ? "bg-gray-600 bg-clip-padding" : ""
        }  cursor-pointer text-font-size-h4 h-full flex justify-center items-center`}
        onClick={() => handleActiveChange(1)}
      >
        Withdraw
      </div>
    </div>
  );
};

export default VaultHeader;
