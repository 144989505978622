import React, { FC } from "react";
import { GetIconByName } from "../../helpers/getIconByName";

const StakeStepOne: FC = () => {
  return (
    <div className="">
      <div className="flex items-center mb-3 justify-end">
        <span className="mr-2 font-bold text-font-size-h5">
          Transfer funds from{" "}
        </span>
        <span className="mr-4">
          <GetIconByName name="binance" size={20} />
        </span>
        <span className="mr-4 font-bold text-font-size-h5">To</span>
        <span className="mr-4 font-bold text-font-size-h5">
          <GetIconByName name="avalanche" size={20} />
        </span>
        <span className="mr-2 font-bold text-font-size-h5">Via</span>
        <span>
          <GetIconByName name="polygon" size={20} />
        </span>
      </div>
      <div className="mb-3 text-font-size-h5 flex justify-end font-bold">
        1.000 ANY ---------{">"} 12.5052 USDC
      </div>
      <div className="mb-3 flex justify-end text-font-size-h6">
        <span>ESTIMATED DURATION :</span> 1.2 min
      </div>
    </div>
  );
};

export default StakeStepOne;
