import { combineReducers } from "redux";

import todoReducer from "./todo/reducer";
import apyReducer from "./apy/reducer"
import walletReducer from "./wallet/reducer"

const rootReducer = combineReducers({
  todo: todoReducer,
  apy: apyReducer,
  wallet: walletReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;