import React, { FC } from "react";
import { GetIconByName } from "../../helpers/getIconByName";

const StakeStepThree: FC = () => {
  return (
    <div className="">
      <div className="mb-3 text-font-size-h5 flex justify-end font-bold">
        Deposit to ASDU-NCASH LP
      </div>
      <div className="mb-3 text-font-size-h5 flex justify-end font-bold">
        1.000 ANY ---------{">"} 12.5052 USDC
      </div>
    </div>
  );
};

export default StakeStepThree;
