import React, { FC } from "react";

// Icons
import { ReactComponent as EthereumIcon } from "../../assets/svg/icons/Ethereum.svg";
import { ReactComponent as AvalancheIcon } from "../../assets/svg/icons/Avalanche.svg";
import { ReactComponent as BinanceIcon } from "../../assets/svg/icons/Binance.svg";
import { ReactComponent as AuroraIcon } from "../../assets/svg/icons/Aurora.svg";
import { ReactComponent as FantomIcon } from "../../assets/svg/icons/Fantom.svg";
import { ReactComponent as NearIcon } from "../../assets/svg/icons/Near.svg";
import { ReactComponent as PolygonIcon } from "../../assets/svg/icons/Polygon.svg";
import { ReactComponent as TerraIcon } from "../../assets/svg/icons/Terra.svg";
import { ReactComponent as XDAIIcon } from "../../assets/svg/icons/XDAI.svg";

interface IconListProps {
  name: string;
  onClick: (value: any) => void;
  isSelected: boolean;
}

const IconList: FC<IconListProps> = ({ name, onClick, isSelected }) => {
  const handleIconByName = (name: string) => {
    switch (name) {
      case "ethereum":
        return <EthereumIcon height={32} width={32} />;
      case "polygon":
        return <PolygonIcon height={32} width={32} />;
      case "avalanche":
        return <AvalancheIcon height={32} width={32} />;
      case "aurora":
        return <AuroraIcon height={32} width={32} />;
      case "xdai":
        return <XDAIIcon height={32} width={32} />;
      case "binance":
        return <BinanceIcon height={32} width={32} />;
      case "near":
        return <NearIcon height={32} width={32} />;
      case "fantom":
        return <FantomIcon height={32} width={32} />;
      case "terra":
        return <TerraIcon height={32} width={32} />;
      default:
        return <EthereumIcon height={32} width={32} />;
    }
  };

  return (
    <div className="w-[80px] rounded h-[48px] border-color-primary border-[1px] flex justify-center items-center cursor-pointer">
      {handleIconByName(name)}
    </div>
  );
};

export default IconList;
