import { useEffect, useState } from "react";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { ReactComponent as ReaTextIcon } from "../../assets/svg/icons/rea-text-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/icons/search-icon.svg";
import { ReactComponent as WalletIcon } from "../../assets/svg/icons/empty-wallet-icon.svg";
import CustomLink from "../core/CustomLink";
import { useLocation } from "react-router-dom";
import CustomButton from "../core/CustomButton";
import CustomInput from "../core/CustomInput";
import { MetaMaskWalletProvider, Sdk } from "etherspot";
import { connectWallet } from "../../helpers/util";
import { useDispatch, useSelector } from "react-redux";
import { getAddressSelector, getConnectedSelector } from "../../redux/wallet/selector";
import { FETCH_CONNECTION_SUCCESS } from "../../redux/wallet/actionTypes";

type locationList = {
  text: string;
  route: string;
};

const DashboardNavbar = () => {
  const locationLists: locationList[] = [
    {
      text: "Explore",
      route: "/home",
    },
    {
      text: "Bond",
      route: "/bond",
    },
    {
      text: "Stake",
      route: "/stake",
    },
    {
      text: "Blog",
      route: "/blog",
    },
    {
      text: "Docs",
      route: "/docs",
    },
    {
      text: "Buy",
      route: "/buy",
    },
  ];
  const dispatch = useDispatch();
  const providerOptions = {};
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("")
  const connected = useSelector(getConnectedSelector)
  const walletAddress = useSelector(getAddressSelector)
  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        setActiveTab(0);
        break;
      case "/bond":
        setActiveTab(1);
        break;
      case "/stake":
        setActiveTab(2);
        break;
      case "/blog":
        setActiveTab(3);
        break;
      case "/docs":
        setActiveTab(4);
        break;
      case "/buy":
        setActiveTab(5);
        break;
      default:
        setActiveTab(17);
        break;
    }
  }, [location.pathname]);
  const handleSearchText = (searchText: string) => {
    console.log(searchText);
  };
  const handleConnectWallet = async () => {
    let pro = await connectWallet();
      dispatch({
        type: FETCH_CONNECTION_SUCCESS,
        payload:{
          signer:pro[1],
          provider:pro[0],
          address: pro[2],
        }
      })
  }
  useEffect( () => {
    
  }, [connected])
  

  return (
    <nav className="relative top-0 right-0 text-color-support-one h-[136px] w-[100%] flex justify-between items-center px-24 border-b-[1px] border-color-neutral-05 border-solid">
      <div>
        <ReaTextIcon />
      </div>
      <div className="flex justify-between items-center gap-2">
        {locationLists.map((location, index) => {
          return (
            <CustomLink
              key={index}
              href={location.route}
              text={location.text}
              calsses={`px-2 text-white font-bold ${
                activeTab === index ? "opacity-100" : "opacity-50"
              } hover:opacity-100`}
            />
          );
        })}
      </div>
      <CustomInput
        onChange={handleSearchText}
        placeholder="Search..."
        icon={<SearchIcon height={19} width={19} />}
        inputClasses="border-2 rounded-2xl max-w-[200px]"
        value={searchQuery}
      />
      <CustomButton
        text={
          walletAddress
            ? `${walletAddress.substring(
                0,
                walletAddress.length - 35
              )}...${walletAddress.substring(35, walletAddress.length)}`
            : "Connect wallet"
        }
        onClick={handleConnectWallet}
        {...(!walletAddress ? { icon: <WalletIcon /> } : {})}
      />
    </nav>
  );
};

export default DashboardNavbar;
