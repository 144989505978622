import React, { FC } from 'react'
interface BarGeneratorProps {
    barNumber: number;
    barHeight?: number;
    barWidth?: number;
}

const BarGenerator: FC<BarGeneratorProps>= ({
    barNumber,
    barHeight = 10,
    barWidth = 4,
}) => {
  return (
    <div className='flex flex-row-reverse justify-start items-end gap-[2px]'>
        <div style={{height: barHeight, width: barWidth, backgroundColor: barNumber >= 8 ? 'lightgreen' :'rgba(255,255,255,0.1)', border: barNumber >= 8 ? '2px solid lightgreen' : '2px solid rgba(255,255,255,0.1)'}} className='rounded-[2px]'></div>
        <div style={{height: barHeight * 0.6, width: barWidth, backgroundColor: barNumber >= 5 ? 'lightgreen' :'rgba(255,255,255,0.1)', border: barNumber >= 5 ? '2px solid lightgreen' : '2px solid rgba(255,255,255,0.1)'}} className='rounded-[2px]'></div>
        <div style={{height: barHeight * 0.3, width: barWidth, backgroundColor: barNumber >= 2 ? 'lightgreen' :'rgba(255,255,255,0.1)', border: barNumber >= 2 ? '2px solid lightgreen' : '2px solid rgba(255,255,255,0.1)'}} className='rounded-[2px]'></div>
    </div>
  )
}

export default BarGenerator