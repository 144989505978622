import { arbitrumStakePools, auroraStakePools, avalancheStakePools, bscStakePools, celoStakePools, cronosStakePools, emeraldStakePools, fantomStakePools, fuseStakePools, harmonyStakePools, hecoStakePools, metisStakePools, moonbeamStakePools, moonriverStakePools, polygonStakePools } from "./configure";
import { indexBy } from "./util";

export const getNetworkLaunchpools = (zap : number) => {
    switch (zap) {
      case 56:
        return indexBy(bscStakePools, 'id');
      case 128:
        return indexBy(hecoStakePools, 'id');
      case 43114:
        return indexBy(avalancheStakePools, 'id');
      case 137:
        return indexBy(polygonStakePools, 'id');
      case 250:
        return indexBy(fantomStakePools, 'id');
      case 1666600000:
        return indexBy(harmonyStakePools, 'id');
      case 42161:
        return indexBy(arbitrumStakePools, 'id');
      case 42220:
        return indexBy(celoStakePools, 'id');
      case 1285:
        return indexBy(moonriverStakePools, 'id');
      case 25:
        return indexBy(cronosStakePools, 'id');
      case 1313161554:
        return indexBy(auroraStakePools, 'id');
      case 122:
        return indexBy(fuseStakePools, 'id');
      case 1088:
        return indexBy(metisStakePools, 'id');
      case 1284:
        return indexBy(moonbeamStakePools, 'id');
      case 42262:
        return indexBy(emeraldStakePools, 'id');
      default:
        return {};
    }
  };