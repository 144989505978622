export interface IRoute {
  path: string;
  title: string;
}
export type RouteKeys = "home" | "vault";

export const routes: Record<RouteKeys, IRoute> = {
  home: {
    path: "/home",
    title: "Home",
  },
  vault: {
    path: "/vault/:id",
    title: "Vault",
  },
};
