import BigNumber from "bignumber.js";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VaultType } from "../../data/data";
import { byDecimals } from "../../helpers/bignumber";
import { formatApy, formatTvl } from "../../helpers/formatter";
import { GetIconByName } from "../../helpers/getIconByName";
import GlassCard from "../core/GlassCard";
import SendTokenImages from "../core/SendTokenImages";
interface VaultCardProps {
  data: VaultType;
  tokens: any;
  apy: any;
}

const yearlyToDaily = (apy : any) => {
  const g = Math.pow(10, Math.log10(apy + 1) / 365) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

const VaultCard: FC<VaultCardProps> = ({ data, tokens, apy }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/vault/${data.id}`);
  };
  const balanceSingle = byDecimals(
    tokens[data.token].tokenBalance,
    data.tokenDecimals
  );
  let balance2;
  if(tokens[data.earnedToken].launchpoolTokenBalance){
    let num = new BigNumber(0)
    balance2 = BigNumber.sum(tokens[data.earnedToken].launchpoolTokenBalance,tokens[data.earnedToken].tokenBalance)
  }
  const sharesBalance = tokens[data.earnedToken].launchpoolTokenBalance
    ? balance2
    : new BigNumber(tokens[data.earnedToken].tokenBalance);
  const deposited = byDecimals(
    sharesBalance.multipliedBy(new BigNumber(data.pricePerFullShare)),
    data.tokenDecimals
  );

  const isBoosted = false;
  const values: any = {};
  let needsApyTooltip = false;
  let needsDailyTooltip = false;

  values.totalApy = apy.totalApy;

  if ('vaultApr' in apy && apy.vaultApr) {
    needsApyTooltip = true;
    values.vaultApr = apy.vaultApr;
    values.vaultDaily = apy.vaultApr / 365;
  }

  if ('tradingApr' in apy && apy.tradingApr) {
    needsApyTooltip = true;
    needsDailyTooltip = true;
    values.tradingApr = apy.tradingApr;
    values.tradingDaily = apy.tradingApr / 365;
  }

  if ('vaultAprDaily' in values || 'tradingAprDaily' in values) {
    values.totalDaily = (values.vaultDaily || 0) + (values.tradingDaily || 0);
  } else {
    values.totalDaily = yearlyToDaily(values.totalApy);
  }

  const formatted = Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      const formattedValue = key.toLowerCase().includes('daily')
        ? formatApy(value, 4)
        : formatApy(value);
      return [key, formattedValue];
    })
  );

  console.log("TVL answers", data.tvl, data.oraclePrice)

  return (
    <GlassCard cardStyleClasses="h-[200px] mb-8 px-8 py-9 cursor-pointer">
      <div className="w-full h-full relative flex" onClick={handleNavigate}>
        <div className="w-[100px] h-full relative">
          <SendTokenImages assets={data.assets} size={64} />
        </div>
        <div className="w-full px-8 flex flex-col justify-between">
          <div className="w-full flex justify-between items-center relative">
            <div className="text-font-size-text-lg font-bold">{data.name}</div>
            <div className="w-1/2 flex justify-between items-center relative">
              <div className="flex items-center">
                <div className="mr-2">
                  <GetIconByName name={data.chain_icon} size={24} />
                </div>
                <GlassCard cardStyleClasses="min-w-[100px] h-[30px] rounded-[2px] px-2 bg-gray-600">
                  <div className="relative w-full h-full font-normal text-font-size-h5 flex justify-center items-center">
                    <span className="capitalize">
                      {data.risks ? data.risks[0] : "HIGH"}
                    </span>
                  </div>
                </GlassCard>
              </div>
              <div className="font-medium">
                {/* <span className="text-gray-600">CHAIN:&nbsp;</span>
                <span className="uppercase">{data.chain}&nbsp;&nbsp;</span> */}
                <span className="text-gray-600">PLATFORM:&nbsp;</span>
                <span className="uppercase">{data.platform}&nbsp;&nbsp;</span>
              </div>
            </div>
          </div>
          <div className="h-[1px] w-full bg-color-neutral-05"></div>
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col items-center">
              <div className="text-font-size-h5 mb-1 uppercase">Wallet</div>
              <div className="text-font-size-h4 font-bold">
                {formatDecimals(balanceSingle)}
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-font-size-h5 mb-1 uppercase">Deposited</div>
              <div className="text-font-size-h4 font-bold">
                {
                  formatDecimals(deposited)
                }
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-font-size-h5 mb-1 uppercase">Apy</div>
              <div className="text-font-size-h4 font-bold">{formatted.totalApy}</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-font-size-h5 mb-1 uppercase">Daily</div>
              <div className="text-font-size-h4 font-bold">{formatted.totalDaily}</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-font-size-h5 mb-1 uppercase">Tvl</div>
              <div className="text-font-size-h4 font-bold">{formatTvl(data.tvl, data.oraclePrice)}</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-font-size-h5 mb-1 uppercase">
                SAFETY SCORE
              </div>
              <div className="text-font-size-h4 font-bold">
                {data.savity_score ? data.savity_score : 0.0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </GlassCard>
  );
};

export default VaultCard;

const formatDecimals = (number) => {
  return number >= 10
    ? number.toFixed(4)
    : number.isEqualTo(0)
    ? 0
    : number.toFixed(8);
};
