import { getCreate2Address } from "@ethersproject/address";
import { keccak256, pack } from "@ethersproject/solidity";
import BigNumber from "bignumber.js";
// import { MultiCall } from "eth-multicall";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import { FETCH_VAULT_SUCCESS } from "../redux/apy/actionTypes";
import { byDecimals } from "./bignumber";
import {
  arbitrumAddressBook,
  arbitrumPools,
  arbitrumZaps,
  auroraAddressBook,
  auroraPools,
  auroraZaps,
  avalanchePools,
  avalancheZaps,
  avaxAddressBook,
  bscAddressBook,
  bscPools,
  bscZaps,
  celoAddressBook,
  celoPools,
  celoZaps,
  cronosAddressBook,
  cronosPools,
  cronosZaps,
  emeraldAddressBook,
  emeraldPools,
  emeraldZaps,
  fantomAddressBook,
  fantomPools,
  fantomZaps,
  fuseAddressBook,
  fusePools,
  fuseZaps,
  harmonyAddressBook,
  harmonyPools,
  harmonyZaps,
  hecoAddressBook,
  hecoPools,
  hecoZaps,
  metisAddressBook,
  metisPools,
  metisZaps,
  moonbeamAddressBook,
  moonbeamPools,
  moonbeamZaps,
  moonriverAddressBook,
  moonriverPools,
  moonriverZaps,
  nativeCoins,
  polygonAddressBook,
  polygonPools,
  polygonZaps,
  vaultABI,
} from "./configure";
import { fetchPrice, whenPricesLoaded } from "./fetchPrice";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";

export const getNetworkZaps = (zap: number) => {
  switch (zap) {
    case 56:
      return bscZaps;
    case 128:
      return hecoZaps;
    case 43114:
      return avalancheZaps;
    case 137:
      return polygonZaps;
    case 250:
      return fantomZaps;
    case 1666600000:
      return harmonyZaps;
    case 42161:
      return arbitrumZaps;
    case 42220:
      return celoZaps;
    case 1285:
      return moonriverZaps;
    case 25:
      return cronosZaps;
    case 1313161554:
      return auroraZaps;
    case 122:
      return fuseZaps;
    case 1088:
      return metisZaps;
    case 1284:
      return moonbeamZaps;
    case 42262:
      return emeraldZaps;
    default:
      return [];
  }
};

export const getNetworkTokens = (zap: number) => {
  const chainId = zap;
  switch (chainId) {
    case 56:
      return bscAddressBook.tokens;
    case 128:
      return hecoAddressBook.tokens;
    case 43114:
      return avaxAddressBook.tokens;
    case 137:
      return polygonAddressBook.tokens;
    case 250:
      return fantomAddressBook.tokens;
    case 1666600000:
      return harmonyAddressBook.tokens;
    case 42161:
      return arbitrumAddressBook.tokens;
    case 42220:
      return celoAddressBook.tokens;
    case 1285:
      return moonriverAddressBook.tokens;
    case 25:
      return cronosAddressBook.tokens;
    case 1313161554:
      return auroraAddressBook.tokens;
    case 122:
      return fuseAddressBook.tokens;
    case 1088:
      return metisAddressBook.tokens;
    case 1284:
      return moonbeamAddressBook.tokens;
    case 42262:
      return emeraldAddressBook.tokens;
    default:
      throw new Error(
        `Create address book for chainId(${chainId}) first. Check out https://github.com/beefyfinance/address-book`
      );
  }
};

export const getEligibleZap = (pool, z: number) => {
  const availableZaps = getNetworkZaps(z);
  const availableTokens = getNetworkTokens(z);
  const burnTokens = getNetworkBurnTokens(z);
  const nativeCoin = getNetworkCoin(z);
  if (pool.assets.length !== 2) return undefined;

  const eligibleNativeCoin = [];
  const tokenSymbols = pool.assets.map((symbol) => {
    if (nativeCoin.symbol === symbol) {
      const wrappedToken = availableTokens[nativeCoin.wrappedSymbol];
      nativeCoin.address = wrappedToken.address;
      eligibleNativeCoin.push(nativeCoin);
      return nativeCoin.wrappedSymbol;
    }
    return symbol;
  });

  let tokenA, tokenB, tokenASymbol, tokenBSymbol;
  let missingTokenSymbols = {};
  const zap = availableZaps.find((zap) => {
    tokenASymbol = tokenSymbols[0];
    tokenBSymbol = tokenSymbols[1];
    tokenA = availableTokens[tokenASymbol];
    tokenB = availableTokens[tokenBSymbol];
    if (tokenA && tokenB) {
      return (
        pool.tokenAddress ===
        computePairAddress(
          zap.ammFactory,
          zap.ammPairInitHash,
          tokenA.address,
          tokenB.address
        )
      );
    } else {
      if (!tokenA) {
        missingTokenSymbols[tokenASymbol] = "";
      }
      if (!tokenB) {
        missingTokenSymbols[tokenBSymbol] = "";
      }
    }
    return false;
  });

  for (const symbol in missingTokenSymbols) {
    console.error("Beefy: token missing in the tokenlist:", symbol);
  }

  const pairHasBurnToken =
    tokenASymbol in burnTokens || tokenBSymbol in burnTokens;
  if (!zap || pairHasBurnToken) return undefined;

  tokenA.allowance = 0;
  tokenB.allowance = 0;

  return {
    ...zap,
    tokens: [tokenA, tokenB, ...eligibleNativeCoin],
  };
};

export const getNetworkBurnTokens = (zap: number) => {
  switch (zap) {
    case 56:
      return {
        [bscAddressBook.tokens.PERA.symbol]: bscAddressBook.tokens.PERA,
        [bscAddressBook.tokens.GUARD.symbol]: bscAddressBook.tokens.GUARD,
        [bscAddressBook.tokens.PEAR.symbol]: bscAddressBook.tokens.PEAR,
        [bscAddressBook.tokens.SING.symbol]: bscAddressBook.tokens.SING,
      };
    case 128:
      return {};
    case 43114:
      return {
        [avaxAddressBook.tokens.SHIBX.symbol]: avaxAddressBook.tokens.SHIBX,
        [avaxAddressBook.tokens.aSING.symbol]: avaxAddressBook.tokens.aSING,
        [avaxAddressBook.tokens.MEAD.symbol]: avaxAddressBook.tokens.MEAD,
      };
    case 137:
      return {
        //  [polygonAddressBook.tokens.xYELD.symbol]: polygonAddressBook.tokens.xYELD,
        [polygonAddressBook.tokens.PEAR.symbol]: polygonAddressBook.tokens.PEAR,
        //  [polygonAddressBook.tokens.pSING.symbol]: polygonAddressBook.tokens.pSING,
      };
    case 250:
      return {
        [fantomAddressBook.tokens.fSING.symbol]: fantomAddressBook.tokens.fSING,
        [fantomAddressBook.tokens.PEAR.symbol]: fantomAddressBook.tokens.PEAR,
      };
    case 1666600000:
      return {};
    case 42161:
      return {};
    case 42220:
      return {};
    case 1285:
      return {};
    case 25:
      return {};
    case 1313161554:
      return {};
    case 122:
      return {};
    case 1088:
      return {};
    case 1284:
      return {};
    case 42262:
      return {};
    default:
      throw new Error(`Create address book for this chainId first.`);
  }
};

export const getNetworkCoin = (zap: number) => {
  return nativeCoins.find((coin) => coin.chainId === zap);
};

export const computePairAddress = (
  factoryAddress,
  pairInitHash,
  tokenA,
  tokenB
) => {
  const [token0, token1] = sortTokens(tokenA, tokenB);
  return getCreate2Address(
    factoryAddress,
    keccak256(["bytes"], [pack(["address", "address"], [token0, token1])]),
    pairInitHash
  );
};

export const sortTokens = (tokenA, tokenB) => {
  if (tokenA === tokenB)
    throw new RangeError(`tokenA should not be equal to tokenB: ${tokenB}`);
  return tokenA.toLowerCase() < tokenB.toLowerCase()
    ? [tokenA, tokenB]
    : [tokenB, tokenA];
};

export const getNetworkMulticall = (zap: number) => {
  switch (zap) {
    case 56:
      return "0xB94858b0bB5437498F5453A16039337e5Fdc269C";
    case 128:
      return "0x2776CF9B6E2Fa7B33A37139C3CB1ee362Ff0356e";
    case 43114:
      return "0x6FfF95AC47b586bDDEea244b3c2fe9c4B07b9F76";
    case 137:
      return "0xC3821F0b56FA4F4794d5d760f94B812DE261361B";
    case 250:
      return "0xC9F6b1B53E056fd04bE5a197ce4B2423d456B982";
    case 1666600000:
      return "0xBa5041B1c06e8c9cFb5dDB4b82BdC52E41EA5FC5";
    case 42161:
      return "0x13aD51a6664973EbD0749a7c84939d973F247921";
    case 42220:
      return "0xa9E6E271b27b20F65394914f8784B3B860dBd259";
    case 1285:
      return "0x7f6fE34C51d5352A0CF375C0Fbe03bD19eCD8460";
    case 25:
      return "0x13aD51a6664973EbD0749a7c84939d973F247921";
    case 1313161554:
      return "0x55f46144bC62e9Af4bAdB71842B62162e2194E90";
    case 122:
      return "0x4f22BD7CE44b0e0B2681A28e300A7285319de3a0";
    case 1088:
      return "0x4fd2e1c2395dc088F36cab06DCe47F88A912fC85";
    case 1284:
      return "0xC9F6b1B53E056fd04bE5a197ce4B2423d456B982";
    case 42262:
      return "0xFE40f6eAD11099D91D51a945c145CFaD1DD15Bb8";
    default:
      return "";
  }
};

export const getRpcUrl = (zap: number) => {
  const settings = networkSettings[zap];
  return settings.rpcUrls[~~(settings.rpcUrls.length * Math.random())];
};

export const networkSettings = {
  56: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: "BSC Mainnet",
    nativeCurrency: {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
  128: {
    chainId: `0x${Number(128).toString(16)}`,
    chainName: "HECO Mainnet",
    nativeCurrency: {
      name: "Huobi Token",
      symbol: "HT",
      decimals: 18,
    },
    rpcUrls: ["https://http-mainnet.hecochain.com"],
    blockExplorerUrls: ["https://hecoinfo.com/"],
  },
  43114: {
    chainId: `0x${Number(43114).toString(16)}`,
    chainName: "Avalanche C-Chain",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"],
  },
  137: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  250: {
    chainId: `0x${Number(250).toString(16)}`,
    chainName: "Fantom Opera",
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ftm.tools"],
    blockExplorerUrls: ["https://ftmscan.com/"],
  },
  1666600000: {
    chainId: `0x${Number(1666600000).toString(16)}`,
    chainName: "Harmony One",
    nativeCurrency: {
      name: "ONE",
      symbol: "ONE",
      decimals: 18,
    },
    rpcUrls: ["https://api.s0.t.hmny.io/"],
    blockExplorerUrls: ["https://explorer.harmony.one/"],
  },
  42161: {
    chainId: `0x${Number(42161).toString(16)}`,
    chainName: "Arbitrum One",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://arbiscan.io/"],
  },
  42220: {
    chainId: `0x${Number(42220).toString(16)}`,
    chainName: "Celo",
    nativeCurrency: {
      name: "CELO",
      symbol: "CELO",
      decimals: 18,
    },
    rpcUrls: ["https://forno.celo.org"],
    blockExplorerUrls: ["https://explorer.celo.org/"],
  },
  1285: {
    chainId: `0x${Number(1285).toString(16)}`,
    chainName: "Moonriver",
    nativeCurrency: {
      name: "Moonriver",
      symbol: "MOVR",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.moonriver.moonbeam.network"],
    blockExplorerUrls: ["https://moonriver.moonscan.io/"],
  },
  25: {
    chainId: `0x${Number(25).toString(16)}`,
    chainName: "Cronos",
    nativeCurrency: {
      name: "CRO",
      symbol: "CRO",
      decimals: 18,
    },
    rpcUrls: ["https://evm.cronos.org"],
    blockExplorerUrls: ["https://cronos.crypto.org/explorer/"],
  },
  122: {
    chainId: `0x${Number(122).toString(16)}`,
    chainName: "Fuse",
    nativeCurrency: {
      name: "FUSE",
      symbol: "FUSE",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.fuse.io"],
    blockExplorerUrls: ["https://explorer.fuse.io/"],
  },
  1088: {
    chainId: `0x${Number(1088).toString(16)}`,
    chainName: "Metis",
    nativeCurrency: {
      name: "METIS",
      symbol: "METIS",
      decimals: 18,
    },
    rpcUrls: ["https://andromeda.metis.io/?owner=1088"],
    blockExplorerUrls: ["https://andromeda-explorer.metis.io/"],
  },
  1313161554: {
    chainId: `0x${Number(1313161554).toString(16)}`,
    chainName: "Aurora Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.aurora.dev"],
    blockExplorerUrls: ["https://explorer.mainnet.aurora.dev/"],
  },
  1284: {
    chainId: `0x${Number(1284).toString(16)}`,
    chainName: "Moonbeam",
    nativeCurrency: {
      name: "GLMR",
      symbol: "GLMR",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.api.moonbeam.network"],
    blockExplorerUrls: ["https://moonscan.io/"],
  },
  42262: {
    chainId: `0x${Number(42262).toString(16)}`,
    chainName: "Oasis Emerald",
    nativeCurrency: {
      name: "Oasis Protocol",
      symbol: "ROSE",
      decimals: 18,
    },
    rpcUrls: ["https://emerald.oasis.dev"],
    blockExplorerUrls: ["https://explorer.emerald.oasis.dev/"],
  },
};

export async function fetchVaultsData(
  web3: any,
  signer: any,
  pools: any,
  zap: number,
  dispatch: any
) {
  if (!web3) {
    // setup default provider to get vault data
    return pools;
  }
  const vault2 = new ethers.Contract(
    pools[0].earnedTokenAddress,
    vaultABI,
    signer
  );
  const data = await vault2.balance();
  //   console.log("KAHDA", ethers.utils.formatEther( data ))
  //   const promise = new Promise((resolve, reject) => {
  //     const multicall = new MultiCall(web3, getNetworkMulticall(zap));
  //     const vaultCalls = pools.map(pool => {
  //       const vault = new ethers.Contract(pool.earnedTokenAddress, vaultABI, signer);
  //       return {
  //         pricePerFullShare: vault.getPricePerFullShare(),
  //         tvl: vault.balance(),
  //       };
  //     });

  //     Promise.all([
  //       multicall.all([vaultCalls]).then(result => result[0]),
  //       whenPricesLoaded()
  //     ])
  //       .then(data => {
  //         const newPools = pools.map((pool, i) => {
  //           const pricePerFullShare = byDecimals(data[0][i].pricePerFullShare, 18).toNumber();
  //           return {
  //             pricePerFullShare: new BigNumber(pricePerFullShare).toNumber() || 1,
  //             tvl: byDecimals(data[0][i].tvl, pool.tokenDecimals).toNumber(),
  //             oraclePrice: fetchPrice({ id: pool.oracleId }) || 0,
  //           };
  //         });
  //         dispatch({
  //             type: FETCH_VAULT_SUCCESS,
  //             payload: newPools,
  //           });
  //           resolve(newPools);
  //       })
  //       .catch(error => {
  //         reject(error.message || error);
  //       });
  //   });
  const multicall = new Multicall({ ethersProvider: web3, tryAggregate: true });
  const contractCallContext: ContractCallContext[] = pools.map(
    (pool, index) => {
      return {
        reference: pool.id,
        contractAddress: pool.earnedTokenAddress,
        abi: vaultABI,
        calls: [
          { reference: "balance", methodName: "balance", methodParameters: [] },
          {
            reference: "getPricePerFullShare",
            methodName: "getPricePerFullShare",
            methodParameters: [],
          },
        ],
      };
    }
  );
  
  const results: ContractCallResults = await multicall.call(
    contractCallContext
  );
  console.log("Updated pools:PPP",results);
  const promise = new Promise((resolve, reject) => {
    Promise.all([
      whenPricesLoaded()
    ]).then((res) =>{
      if (results.results) {
        let newPools = pools.map((pool, index) => {
          const pricePerFullShare = ethers.utils.formatEther(
            results.results[pool.id].callsReturnContext[1].returnValues[0]
          );
          return {
            ...pool,
            pricePerFullShare: new BigNumber(pricePerFullShare).toNumber() || 1,
            tvl: Number(
              ethers.utils.formatEther(
                results.results[pool.id].callsReturnContext[0].returnValues[0]
              )
            ),
            oraclePrice: fetchPrice({ id: pool.oracleId }) || 0,
          };
        });
        dispatch({
          type: FETCH_VAULT_SUCCESS,
          payload: newPools,
        });
      }
    })

  });

  
  return [];
}

export const getNetworkPools = (zap: number) => {
  switch (zap) {
    case 56:
      return bscPools;
    case 128:
      return hecoPools;
    case 43114:
      return avalanchePools;
    case 137:
      return polygonPools;
    case 250:
      return fantomPools;
    case 1666600000:
      return harmonyPools;
    case 42161:
      return arbitrumPools;
    case 42220:
      return celoPools;
    case 1285:
      return moonriverPools;
    case 25:
      return cronosPools;
    case 1313161554:
      return auroraPools;
    case 122:
      return fusePools;
    case 1088:
      return metisPools;
    case 1284:
      return moonbeamPools;
    case 42262:
      return emeraldPools;
    default:
      return [];
  }
};
